import { useCommonContext } from "../../common-context";
import LocationCard from "../../components/location-card";
import { useHgLocations, useQuery } from "../../hooks";
import Layout from "../../layout";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import LenderHGsSelectControl from "../../components/select-control/lender-hgs-select-control";
import UseGetLenderHgSitesLocation from "../../hooks/get-lender-hg-sites-location";
import _ from "lodash";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import {
  FilterHorizontalIcon,
  Location01Icon,
  LocationAdd01Icon,
} from "hugeicons-react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination-control/pagination";

const Locations = () => {
  const [{ user, logo }] = useCommonContext();
  const [userLocationCollection, setUserLocationCollection] = useState([]);
  const [hgIdCollection, setHgIdCollection] = useState();
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.unloaded);
  const queryHelper = useQuery();
  const location = useLocation();
  // console.log(hgIdCollection, userLocationCollection);

  const searchFilterData = useMemo(() => {
    return {
      start: parseInt(queryHelper.getValue("start"), 10) || 0,
      records: 10,
    };
  }, [location.search]);
  const [page, setPage] = useState({
    start: searchFilterData.start,
    records: searchFilterData.records,
    totalRecords: 10,
  });
  // useHgLocations
  const { getLocationByIds } = useHgLocations();
  const getSites = UseGetLenderHgSitesLocation();
  const navigate = useNavigate();
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const locationAsyncMethod = async (locationId) => {
    const loc = await getLocationByIds(locationId);
    return loc;
  };
  // console.log(user.data.userType);

  useEffect(() => {
    setIsLoading(apiLoadingStatus.loading);

    navigate(`/locations?start=${page.start}&records=${page.records}`);
    let actionStatus = null;
    getSites({
      hgIdCollection: hgIdCollection,
      start: page.start,
      records: page.records,
      userType: user.data.userType,
    })
      .then(([res, status, totalCount]) => {
        setIsLoading(apiLoadingStatus.loaded);
        actionStatus = status;
        setUserLocationCollection(res);
        setPage({
          ...page,
          totalRecords: totalCount,
        });
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        setIsLoading(apiLoadingStatus.error);
        console.log(err);
      })
      .finally(() => {
        switch (actionStatus) {
          default:
            break;
          case "Unauthorized":
            navigate("/login");
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "UsedBefore":
            showErrorMassage("Used Before");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Admin LockedOut ");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credentials Newly LockedOut");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credentials");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Success":
            break;
        }
      });
  }, [user.data.hgSiteIdsCollection, hgIdCollection, page.start]);

  useEffect(() => {
    document.title = "Locations | DentiRate";
    window.scrollTo(0, 0);
  }, [page.start]);

  return (
    <div>
      <Layout
        isLoading={isLoading === apiLoadingStatus.loading}
        user={{
          type: "admin",
          name: user.data.firstName + " " + user.data.lastName,
          logo: logo.data?.url,
        }}
      >
        {/* <LoadingSpinner isLoading={isLoading === apiLoadingStatus.loading} /> */}
        <Container className={styles["container"]}>
          <div className={styles["select"]}>
            {user.data.userType === "Lender" && (
              <div className={styles["hcg"]}>
                <FilterHorizontalIcon
                  color="#767676"
                  className={styles["filter-icon"]}
                />
                <LenderHGsSelectControl
                  onChange={(o) => {
                    !!o
                      ? setHgIdCollection(o.id)
                      : setHgIdCollection(undefined);
                    setPage({
                      ...page,
                      start: 0,
                    });
                  }}
                  className={styles["select"]}
                />
              </div>
            )}
            {user.data.userType === "HealthcareGroup" && (
              <div className={styles["btn"]}>
                <button
                  className={`${styles["new-location"]} } btn btn-primary`}
                  onClick={() => navigate("/locations/new")}
                >
                  <LocationAdd01Icon className={styles["icon"]} /> New Location
                </button>
              </div>
            )}
          </div>

          {!!user.data.hgSiteIdsCollection &&
            !!userLocationCollection &&
            userLocationCollection.length > 0 &&
            userLocationCollection.map((location, index) => {
              return <LocationCard location={location} key={index} />;
            })}

          {page.totalRecords > page.records && (
            <Pagination
              start={page.start}
              records={page.records}
              spanWidth={5}
              totalCount={page.totalRecords}
              onClick={(value) =>
                setPage({
                  ...page,
                  start: value,
                })
              }
              displayViewOptions={false}
              handleViewOptions={(records) =>
                setPage({
                  ...page,
                  records: records,
                })
              }
              // className={styles["pagination"]}
            />
          )}
        </Container>
      </Layout>
    </div>
  );
};

export default Locations;
