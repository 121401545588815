import _ from "lodash";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

function convertUsersDates(users) {
  return users.map((tempUser) => ({
    ...tempUser,
    createDate: !!tempUser.createDate
      ? new Date(tempUser.createDate)
      : undefined,
    submitDate: !!tempUser.submitDate
      ? new Date(tempUser.submitDate)
      : undefined,
    lastUpdateDate: !!tempUser.lastUpdateDate
      ? new Date(tempUser.lastUpdateDate)
      : undefined,
    lastUnsuccessfulAccessAttempt: !!tempUser.lastUnsuccessfulAccessAttempt
      ? new Date(tempUser.lastUnsuccessfulAccessAttempt)
      : undefined,
  }));
}

const useGetApplication = () => {
  const loadData = async ({
    start,
    records,
    query,
    minAmount,
    maxAmount,
    simplifiedApplicationStatus,
    treatmentType,
    locationIdCollection,
    healthcareGroupId,
  }) => {
    const data = {
      start,
      records,
      query,
      minAmount,
      maxAmount,
      simplifiedApplicationStatus,
      treatmentType,
      locationIdCollection,
      healthcareGroupId,
      sortDirection: "Desc",
      sortField: "CreateDate",
    };

    // make sure you
    return apiHelperMethods
      .getWithReturn(apiUrls.userIdentityUrls.applicationPaginated, data)
      .then((res) => {
        let tempUsers = res.data?.data?.consumerApplicationCollection;
        let totalRecords = res.data?.data?.totalCount;
        if (!tempUsers) {
          return Promise.reject("No user found!");
        }
        tempUsers = convertUsersDates(tempUsers);
        const actionStatus = _.get(res, "headers.action-status", null);

        return Promise.resolve([tempUsers, actionStatus, totalRecords]);
      });
  };
  return loadData;
};

export default useGetApplication;
