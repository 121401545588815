import React from "react";
import styles from "./styles.module.scss";
import {
  CalendarCheckIn01Icon,
  DollarCircleIcon,
  DollarReceive01Icon,
  Link01Icon,
  MoneyExchange01Icon,
  Payment01Icon,
  Payment02Icon,
  ProfitIcon,
  TaskDone01Icon,
} from "hugeicons-react";
import { Container, Col, Row } from "react-bootstrap";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";

const BillingCard = ({
  date = null,
  paymentStatus = "",
  numberOfLeads = 0,
  amount = 0,
  dueDate = null,
}) => {
  return (
    <div className={styles["container"]}>
      <Row className={styles["top-section"]}>
        <Col className={styles["date"]}>{date}</Col>
        <Col className={styles["top-right-section"]}>
          <div className={styles["payment-status"]}>
            <DollarCircleIcon size={24} color="#757575" />
            <p style={{ margin: 0, marginRight: "10px" }}>Payment Status</p>
            <div className={`${styles["status"]} ${styles[paymentStatus]}`}>
              {paymentStatus}
            </div>
          </div>
          <div className={styles["link-icon"]}>
            <Link01Icon size={24} />
          </div>
        </Col>
      </Row>
      <Row className={styles["bottom-section"]}>
        <Col md={4}>
          <Row className={styles["first-column"]}>
            <Col xs={9} sm={6} md={12} lg={9} className={styles["column"]}>
              <div>
                <TaskDone01Icon color="#757575" className={styles["icon"]} />
              </div>
              <p>Number of Leads</p>
            </Col>
            <Col xs={3} sm={6} md={12} lg={3} className={styles["columnTwo"]}>
              <div
                className={`${styles["number-of-leads"]} ${styles["data-column"]}`}
              >
                {numberOfLeads}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row className={styles["middle-column"]}>
            <Col xs={8} sm={6} md={12} lg={8} className={styles["column"]}>
              <MoneyExchange01Icon
                color="#757575"
                className={styles["icon"]}
                size={24}
              />
              <p>Amunt Due</p>
            </Col>
            <Col xs={4} sm={6} md={12} lg={4} className={styles["columnTwo"]}>
              <div
                className={`${styles["amount-due"]} ${styles["data-column"]}`}
              >
                {commaSeperatedCurrency(amount, 0)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row className={styles["last-column"]}>
            <Col xs={6} sm={6} md={12} lg={6} className={styles["column"]}>
              <CalendarCheckIn01Icon
                color="#757575"
                className={styles["icon"]}
              />
              <p>Due Date</p>
            </Col>
            <Col xs={6} sm={6} md={12} lg={6} className={styles["columnTwo"]}>
              <div className={`${styles["due-date"]} ${styles["data-column"]}`}>
                {dueDate}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BillingCard;
