import React, { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import Layout from "../../layout";

import UserItem from "../../components/user-item";
import UseGetPaginatedUserList from "../../hooks/use-get-paginated-user-list";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { useCommonContext } from "../../common-context";
import SwitchUsersList from "../../components/switch-users-list/switch-users-list";
import UsersFilterSearch from "../../components/users-filter-search";
import { UserAdd01Icon, UserAdd02Icon } from "hugeicons-react";
import styles from "./styles.module.scss";
import Pagination from "../../components/pagination-control/pagination";
import UseGetPaginatedInvitationsList from "../../hooks/use-get-paginated-invitation-list";
import useHgLocations from "../../hooks/use-hg-locations1";
import UsersTab from "./users-tab";
import InvitationsTab from "./invitations-tab";
import { useLocation, useNavigate } from "react-router-dom";

const UserManagement = () => {
  // const [{ user }] = useCommonContext();
  // console.log(user.id);
  const [invitedUsers2, setInvitedUsers2] = useState({
    totalCount: 0,
    invitedUsersCollection: [],
  });
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [users, setUsers] = useState({
    totalCount: 0,
    userList: [],
  });
  const [noUser, setNoUser] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [tab, setTab] = useState("users-tab");
  const [searchFilterData, setSearchFilterData] = useState({
    sortDirection: "",
    sortField: "CreateDate",
    query: "",
    isActive: true,
    isDeleted: "",
    start: 0,
    records: 10,
  });
  const [invitationsData, setInvitationsData] = useState({
    email: "",
    expired: "",
    // query: "",
    start: 0,
    records: 10,
  });
  //queryString
  const [queryState, setQuery] = useState({
    start: 0,
    limit: 10,
    tab: "users-tab",
  });

  const handleUsersList = UseGetPaginatedUserList();
  const handleInvitationList = UseGetPaginatedInvitationsList();
  const location = useLocation();
  const navigate = useNavigate();

  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const handleChangeTab = (tab) => {
    // console.log(tab);
    const queryString = new URLSearchParams(location.search);

    if (tab === "users-tab") {
      queryString.set("tab", "users");
      queryString.set("start", 0);
    }
    if (tab === "invitations-tab") {
      queryString.set("tab", "invitations");
      queryString.set("start", 0);
    }
    setTab(tab);
    navigate(`/user-management?${queryString.toString()}`);
  };

  const handleSearch = (searchQuery) => {
    // console.log(searchQuery);
    const queryString = new URLSearchParams(location.search);
    queryString.set("search", searchQuery);
    // console.log(queryString.toString());
    navigate(`/user-management?${queryString.toString()}`);
  };

  const handlePagination = (value) => {
    const queryString = new URLSearchParams(location.search);
    queryString.set("start", value);
    navigate(`/user-management?${queryString.toString()}`);
  };

  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    const tempStart = parseInt(queryString.get("start") || 0, 10);
    const tempLimit = parseInt(queryString.get("limit") || 10, 10);
    const tempSearch = queryString.get("search") || "";
    let tempTab = queryString.get("tab") || "users-tab";
    let tempIsActive = null;

    switch (queryString.get("tab")) {
      case "users":
        tempTab = "users-tab";
        break;
      case "invitations":
        tempTab = "invitations-tab";
        break;
      default:
        tempTab = "users-tab";
        break;
    }

    switch (queryString.get("status")) {
      case "all":
        tempIsActive = null;
        break;
      case "active":
        tempIsActive = true;
        break;
      case "inactive":
        tempIsActive = false;
        break;
      default:
        tempIsActive = true;
        break;
    }

    const tempQuery = {
      query: tempSearch,
      start: tempStart,
      limit: tempLimit,
      tab: tempTab,
      isActive: tempIsActive,
    };
    // console.log("tempQuery", tempQuery);
    // // setQuery({ ...tempQuery });
    if (tempQuery.tab === "users-tab") {
      setTab("users-tab");
      setSearchFilterData({
        ...searchFilterData,
        query: tempQuery.query,
        start: tempQuery.start,
        records: tempQuery.limit,
        isActive: tempQuery.isActive,
      });

      let actionStatus = null;
      setLoadingStatus(apiLoadingStatus.loading);
      // console.log(searchFilterData);
      handleUsersList({
        sortDirection: 1,
        sortField: "CreateDate",
        query: tempQuery.query,
        isActive: tempQuery.isActive,
        isDeleted: "",
        start: tempQuery.start,
        records: tempQuery.limit,
      })
        .then(([res, status]) => {
          // console.log(res, status);
          actionStatus = status;
          // setUsers(res);
          setUsers({
            totalCount: res?.totalCount || 0,
            userList: res?.userList || [],
          });
          users.userList.length === 0 ? setNoUser(true) : setNoUser(false);
        })
        .catch((err) => {
          // console.log(err);
          actionStatus = _.get(err, "response.headers.action-status", null);
          err === "No user found!" && setNoUser(true);
        })
        .finally(() => {
          // console.log(users);
          setLoadingStatus(apiLoadingStatus.loaded);
          switch (actionStatus) {
            default:
              showErrorMassage("Unknown error");
              break;
            case "InvalidCredentials":
              showErrorMassage("Invalid Credientials");
              break;
            case "Failed":
              showErrorMassage("Failed");
              break;
            case "InvalidCredentialsNewlyLockedOut":
              showErrorMassage("Invalid Credientials Newly LockedOut");
              break;
            case "UsedBefore":
              showErrorMassage("UsedBefore");
              break;
            case "SameAsPrevious":
              showErrorMassage("Same As Previous");
              break;
            case "AdminLockedOut":
              showErrorMassage("Your account was just locked out.");
              break;
            case "ValidationError":
              showErrorMassage("Validation Error");
              break;
            case "NotExists":
              showErrorMassage("Not Exists");
              break;
            case "Unauthorized":
              navigate("/login");
              break;
            case "Forbidden":
              showErrorMassage("Forbidden ");
              break;
            case "Success":
              break;
          }
        });
    } else {
      setTab("invitations-tab");
      setInvitationsData({
        ...invitationsData,
        start: tempQuery.start,
        records: tempQuery.limit,
      });

      let actionStatus = null;
      setLoadingStatus(apiLoadingStatus.loading);

      handleInvitationList({
        ...invitationsData,
        start: tempQuery.start,
        records: tempQuery.limit,
      })
        .then(([res, status]) => {
          actionStatus = status;
          setInvitedUsers2({
            totalCount: res?.totalCount || 0,
            invitedUsersCollection: res?.invitedUserCollection || [],
          });
          // console.log(res, status);
          // actionStatus = status;
          // setInvitedUsers(res);
          // invitedUsers.length === 0 && setNoUser(true);
        })
        .catch((err) => {
          // console.log(err);
          actionStatus = _.get(err, "response.headers.action-status", null);
          // err === "No user found!" && setNoUser(true);
        })
        .finally(() => {
          // console.log(invitedUsers.length);
          setLoadingStatus(apiLoadingStatus.loaded);
          switch (actionStatus) {
            case "Unauthorized":
              navigate("/login");
              break;
            default:
              showErrorMassage("Unknown error");
              break;
            case "InvalidCredentials":
              showErrorMassage("Invalid Credientials");
              break;
            case "Failed":
              showErrorMassage("Failed");
              break;
            case "InvalidCredentialsNewlyLockedOut":
              showErrorMassage("Invalid Credientials Newly LockedOut");
              break;
            case "UsedBefore":
              showErrorMassage("UsedBefore");
              break;
            case "SameAsPrevious":
              showErrorMassage("Same As Previous");
              break;
            case "AdminLockedOut":
              showErrorMassage("Your account was just locked out.");
              break;
            case "ValidationError":
              showErrorMassage("Validation Error");
              break;
            case "NotExists":
              showErrorMassage("Not Exists");
              break;
            case "Forbidden":
              showErrorMassage("Forbidden ");
              break;
            case "Success":
              break;
          }
        });
    }
  }, [location.search]);
  // console.log(invitedUsers2);
  return (
    <>
      <Layout isLoading={loadingStatus === apiLoadingStatus.loading}>
        {/* <LoadingSpinner
          isLoading={loadingStatus === apiLoadingStatus.loading}
        /> */}
        <Container className={styles["container"]}>
          <SwitchUsersList callbackfunction={handleChangeTab} tab={tab} />

          <UsersFilterSearch
            tab={tab}
            handleSearch={handleSearch}
            searchFilterData={searchFilterData}
            setSearchFilterData={setSearchFilterData}
          >
            <button
              className={styles["btn"]}
              onClick={() => {
                navigate("/add-user");
              }}
            >
              <UserAdd02Icon />
              New User
            </button>
          </UsersFilterSearch>

          {tab === "users-tab" && (
            <UsersTab
              users={users.userList}
              totalCount={users.totalCount}
              noUser={noUser}
              start={searchFilterData.start}
              records={searchFilterData.records}
              handlePagination={handlePagination}
            />
          )}

          {tab === "invitations-tab" && (
            <InvitationsTab
              invitedUsers={invitedUsers2}
              loadingStatus={loadingStatus}
              start={invitationsData.start}
              records={invitationsData.records}
              handlePagination={handlePagination}
            />
          )}

          {/* <Pagination
            start={searchFilterData.start}
            records={searchFilterData.records}
            spanWidth={5}
            totalCount={1000}
            // onClick={(value, records) =>
            //   setSearchFilterData({
            //     ...searchFilterData,
            //     start: value,
            //   })
            // }
            // handleViewOptions={(value) =>
            //   setSearchFilterData({
            //     ...searchFilterData,
            //     Records: value,
            //   })
            // }
            // className={styles["pagination"]}
          /> */}
        </Container>
      </Layout>
    </>
  );
};

export default UserManagement;
