import { apiLoadingStatus } from "../infrastructure/constants/api";
import en from "../locals/en";
import Es from "../locals/es";

const getCommonContextInitalValues = () => {
  return {
    user: {
      data: null,
      loadingStatus: apiLoadingStatus.unloaded,
    },
    settings: {},
    logo: { data: null, loadingStatus: apiLoadingStatus.unloaded },
    sqLogo: { data: null, loadingStatus: apiLoadingStatus.unloaded },
    language: "en",
  };
};

export default getCommonContextInitalValues;
