import { Col, Row } from "react-bootstrap";
import applicationStatus from "../../infrastructure/constants/enums/simplified-application-status";
import styles from "./styles.module.scss";
import {
  ArrowTurnForwardIcon,
  Calendar04Icon,
  Copy01Icon,
  MedicineBottle01Icon,
  Payment02Icon,
  Wallet03Icon,
} from "hugeicons-react";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import simplifiedApplicationStatus from "../../infrastructure/constants/enums/simplified-application-status";

const ApplicationChildren = ({
  amount = 0,
  createDate = null,
  preferredMonthlyPayment = "",
  treatmentType = "",
  status = "",
  applicationInitiationType = "",
}) => {
  // console.log(status);
  return (
    <div className={styles["child-container"]}>
      <div className={styles["name-col"]}>
        {applicationInitiationType === "Extension" ? (
          <>
            <ArrowTurnForwardIcon /> Extension
          </>
        ) : (
          <>
            <Copy01Icon /> Duplication
          </>
        )}
      </div>
      <div className={styles["info-col"]}>
        <Row className={styles["first-row"]}>
          <Col md={12} lg={6} className={styles["name-date-box"]}>
            <div className={styles["date-box"]}>
              <Calendar04Icon className={styles["date-icon"]} />
              <span>
                {`${new Date(createDate).getDate()}/${
                  new Date(createDate).getMonth() + 1
                }/${new Date(createDate).getFullYear()}`}
                {createDate?.day}
              </span>
            </div>
          </Col>

          <Col md={12} lg={6} className={styles["action-box"]}>
            <span
              className={`${styles["application-status"]} ${
                status === "Initiated"
                  ? styles["initiated"]
                  : status === "ProcessedNoLender"
                  ? styles["processed-no-lender"]
                  : status === "PendingApplicantSubmission"
                  ? styles["pending-applicant-submission"]
                  : status === "Submitted"
                  ? styles["submitted"]
                  : status === "Processing"
                  ? styles["processing"]
                  : status === "Approved"
                  ? styles["processed-successfuly-with-approval"]
                  : status === "NoApproval"
                  ? styles["processed-no-approval"]
                  : status === "ProcessedNoLender"
                  ? styles["processed-no-lender"]
                  : status === "Error"
                  ? styles["processing-error"]
                  : status === "LenderLinkClicked"
                  ? styles["lender-link-clicked"]
                  : status === "Applied"
                  ? styles["applicant-applied"]
                  : status === "Abandoned"
                  ? styles["abandoned"]
                  : styles["none"]
              }`}
            >
              {simplifiedApplicationStatus.getLabel(status)}
            </span>
          </Col>
        </Row>
        <div className={styles["third-row"]}>
          <div className={styles["first-col"]}>
            <div className={styles["title-col"]}>
              <div className={styles["middle-icons"]}>
                <Wallet03Icon className={styles["wallet-icon"]} />
              </div>
              <div className={styles["title"]}>Amount</div>
            </div>
            <div className={styles["value-col"]}>
              <span className={styles["value"]}>
                {/* {amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              })} */}
                {commaSeperatedCurrency(amount, 0)}
              </span>
            </div>
          </div>

          <div className={styles["seprator"]}></div>

          <div className={styles["middle-col"]}>
            <div className={styles["title-col"]}>
              <div className={styles["middle-icons"]}>
                <MedicineBottle01Icon className={styles["medical-icon"]} />
              </div>
              <div className={styles["title"]}>Treatment Type</div>
            </div>
            <div className={styles["value-col"]}>
              <div className={styles["value"]}>{treatmentType}</div>
            </div>
          </div>

          <div className={styles["seprator"]}></div>

          <div className={styles["last-col"]}>
            <div className={styles["title-col"]} style={{ paddingRight: 0 }}>
              <div className={styles["middle-icons"]}>
                <Payment02Icon className={styles["payment-icon"]} />
              </div>
              <div className={styles["title"]}>Prefered Monthly Payment</div>
            </div>
            <div className={styles["value-col"]}>
              <span className={styles["value"]}>
                {preferredMonthlyPayment !== 0
                  ? commaSeperatedCurrency(preferredMonthlyPayment, 0)
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationChildren;
