import React from "react";
import styles from "./styles.module.scss";
import { ArrowLeftDoubleIcon, Search01Icon } from "hugeicons-react";

const layout = ({ children = <></> }) => {
  return (
    <div className={styles["container"]}>
      <header className={styles["header"]}>
        <span>DentiRate Help Center</span>
        <span className={styles["search"]}>
          <Search01Icon />
        </span>
      </header>

      <main className={styles["main"]}>
        <div className={styles["sidebar"]}>
          <h2>Contents</h2>
          <li>
            <ul>item1</ul>
            <ul>item2</ul>
            <ul>item3</ul>
          </li>
        </div>
        <div className={styles["content"]}>
          <header>
            <ArrowLeftDoubleIcon />
          </header>
          <content>{children}</content>
        </div>
      </main>
    </div>
  );
};

layout.propTypes = {};

export default layout;
