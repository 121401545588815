import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { FilterHorizontalIcon, Search01Icon } from "hugeicons-react";
import SelectControl from "../select-control/select-control";
import treatmentType from "../../infrastructure/constants/enums/treatment-types";
import applicationStatus from "../../infrastructure/constants/enums/simplified-application-status";
import { Col, Container, Row } from "react-bootstrap";
import ReactSlider from "react-slider";
import _ from "lodash";
import {
  HgSitesSelectControl,
  LenderHGSiteSelectControl,
} from "../select-control";
import PropTypes from "prop-types";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import { use } from "react";

const FilterSearch = ({
  searchFilterData,
  setSearchFilterData,
  children = <></>,
}) => {
  const [showRange, setShowRange] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [treatmentTypeValue, setTreatmentTypeValue] = useState(null);
  const [treatmentTypeLabel, setTreatmentTypeLabel] = useState(null);
  const rangeBox = useRef();
  const filterSearchDataInput = (e) => {
    const name = e.target.name;
    setSearchFilterData({
      ...searchFilterData,
      [name]: e.target.value.toUpperCase(),
    });
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the box
    if (rangeBox.current && !rangeBox.current.contains(event.target)) {
      setShowRange(false); // Update the state when clicking outside
    }
  };

  useEffect(() => {
    // Attach event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["first-row"]}>
          <div className={styles["search-container"]}>
            <input
              type="text"
              name="query"
              placeholder="Search"
              defaultValue={searchFilterData.query}
              className={styles["search-text"]}
              onChange={(e) => {
                setSearchQuery(e);
                if (e.target.value === "") {
                  filterSearchDataInput(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  filterSearchDataInput(e);
                }
              }}
            />
            <Search01Icon
              className={styles["search-icon"]}
              onClick={() => {
                if (searchQuery !== "") {
                  filterSearchDataInput(searchQuery);
                }
              }}
            />
          </div>
          {children}
        </div>

        <div className={styles["filter-container"]}>
          <FilterHorizontalIcon
            color="#767676"
            className={styles["filter-icon"]}
          />
          {/* <Container> */}
          <Row className={styles["filter-row"]}>
            <Col
              sm={12}
              md={6}
              lg={3}
              className={`relative ${styles["range-filter"]}`}
              ref={rangeBox}
            >
              {/* TODO: Create a custom control for this */}
              <button
                className={`${styles["price-button"]} ${
                  styles[showRange && "notShow"]
                }`}
                onClick={() => setShowRange(!showRange)}
              >
                {searchFilterData.minAmount >= 0
                  ? commaSeperatedCurrency(searchFilterData.minAmount, 0)
                  : "No min "}
                –
                {searchFilterData.maxAmount <= 20000
                  ? commaSeperatedCurrency(searchFilterData.maxAmount, 0)
                  : " No max"}
              </button>
              <div className={styles[showRange ? "price-selector" : "notShow"]}>
                <ReactSlider
                  value={[
                    !searchFilterData.minAmount ||
                    searchFilterData.minAmount < 0
                      ? 0
                      : searchFilterData.minAmount,
                    !searchFilterData.maxAmount ||
                    searchFilterData.maxAmount >= 20000
                      ? 20000
                      : searchFilterData.maxAmount,
                  ]}
                  onAfterChange={(value, index) => {
                    setSearchFilterData({
                      ...searchFilterData,
                      minAmount: value[0] <= 0 ? undefined : value[0],
                      maxAmount:
                        !value[1] || value[1] >= 20000 ? undefined : value[1],
                    });
                    setShowRange(false);
                  }}
                  min={0}
                  max={20000}
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props, state) => {
                    const { key, ...restProps } = props;
                    return (
                      <div key={key} {...restProps}>
                        {state.valueNow > 20000 ? (
                          "Max"
                        ) : (
                          <>{state.valueNow >= 0 ? state.valueNow : "Min"}</>
                        )}
                      </div>
                    );
                  }}
                  pearling={true}
                  minDistance={50}
                  step={100}
                />
              </div>
            </Col>

            <Col sm={12} md={6} lg={3} className={styles["second-filter"]}>
              <SelectControl
                className={styles["filter"]}
                placeholder="Status"
                options={applicationStatus.collection}
                value={
                  !searchFilterData.applicationStatusCollection ||
                  searchFilterData.applicationStatusCollection.length === 0
                    ? []
                    : applicationStatus.collection.filter(
                        (i) =>
                          searchFilterData.applicationStatusCollection.indexOf(
                            i.value
                          ) > -1
                      )
                }
                onChange={(v) => {
                  !!v
                    ? setSearchFilterData({
                        ...searchFilterData,
                        applicationStatusCollection: v.value,
                      })
                    : setSearchFilterData({
                        ...searchFilterData,
                        applicationStatusCollection: null,
                      });
                }}
                isMulti={false}
              />
            </Col>

            <Col sm={12} md={6} lg={3} className={styles["third-filter"]}>
              {/* // singleValue */}
              <SelectControl
                className={styles["filter"]}
                options={treatmentType.collection}
                placeholder="Treatment"
                value={
                  treatmentTypeValue === null
                    ? null
                    : { label: treatmentTypeLabel, value: treatmentTypeValue }
                }
                onChange={(v) => {
                  if (!v) {
                    setSearchFilterData({
                      ...searchFilterData,
                      treatmentType: null,
                    });
                    setTreatmentTypeValue(null);
                  } else {
                    setTreatmentTypeValue(v.value);
                    setTreatmentTypeLabel(v.label);
                    setSearchFilterData({
                      ...searchFilterData,
                      treatmentType: v.value,
                    });
                  }
                }}
              />
            </Col>

            {/* Multi values */}
            {/* <SelectControl
                  options={treatmentType.collection}
                  placeholder="Treatment"
                  value={
                    !multiValues || multiValues.length === 0
                      ? []
                      : treatmentType.collection.filter(
                          (i) => multiValues.indexOf(i.value) > -1
                        )
                  }
                  onChange={(v) => {
                    console.log(v);
                    setMultiValues((v || []).map((i) => i.value));
                  }}
                  isMulti
                /> */}

            <Col sm={12} md={6} lg={3} className={styles["fourth-filter"]}>
              <HgSitesSelectControl
                className={styles["filter"]}
                onChange={(value) =>
                  setSearchFilterData({
                    ...searchFilterData,
                    locationIdCollection: value ? [value.id] : undefined,
                  })
                }
              />
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </div>
    </>
  );
};
FilterSearch.propTypes = {
  searchFilterData: PropTypes.any,
  setSearchFilterData: PropTypes.func,
};
export default FilterSearch;
