import { useCommonContext } from "../common-context";
import { apiUrls } from "../infrastructure/constants/api";
import apiHelperMethods from "../infrastructure/helpers/api-helpers";

const useGetLogo = (square = false) => {
  const [{ user }] = useCommonContext();
  const loadLogo = () => {
    const tempUserType = user.data?.userType;
    const tempOrganizationId = user.data?.organizationId;
    // if (tempUserType) {
    //   return Promise.reject("no user type found!");
    // }
    // if (tempOrganizationId) {
    //   return Promise.reject("no organization ID found!");
    // }
    return apiHelperMethods.getWithReturn(apiUrls.userIdentityUrls.logo, {
      organizationId: tempOrganizationId,
      organizationType: tempUserType,
      logoType: square ? "square" : "main",
    });
  };
  return loadLogo;
};

export default useGetLogo;
