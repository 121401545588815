import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import {
  AddTeamIcon,
  CircleLockAdd01Icon,
  CircleLockAdd02Icon,
  UserAdd01Icon,
  UserAdd02Icon,
  UserCircle02Icon,
  UserCircleIcon,
  UserGroupIcon,
  UserListIcon,
} from "hugeicons-react";
// import { propTypes } from "react-bootstrap/esm/Image";

const SwitchUsersList = ({
  tab = "users-tab",
  callbackfunction = () => {},
}) => {
  // const [tab, setTab] = useState("users");

  return (
    <>
      <div className={styles["container"]}>
        <div
          className={`${styles["tab"]} ${styles["users"]} ${
            tab === "users-tab" ? styles["active"] : ""
          }`}
          onClick={() => {
            callbackfunction("users-tab");
          }}
        >
          <UserGroupIcon />
          Users
        </div>
        <div
          className={`${styles["tab"]} ${styles["invitations"]} ${
            tab === "invitations-tab" ? styles["active"] : ""
          }`}
          onClick={() => {
            callbackfunction("invitations-tab");
          }}
        >
          <AddTeamIcon />
          Invitations
        </div>
      </div>
    </>
  );
};

SwitchUsersList.propTypes = {
  callbackfunction: PropTypes.func,
};

export default SwitchUsersList;
