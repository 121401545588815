import {
  Calendar04Icon,
  MedicineBottle01Icon,
  Payment02Icon,
  Wallet03Icon,
  Call02Icon,
  Mail02Icon,
  IdIcon,
  Location01Icon,
} from "hugeicons-react";
import styles from "./styles.module.scss";
import { Col, Row } from "react-bootstrap";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import useHgLocations from "../../hooks/use-hg-locations1";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LeadCard = ({
  amount = 0,
  firstName = "",
  lastName = "",
  createDate = null,
  preferredMonthlyPayment = "",
  treatmentType = "",
  emailAddress = "",
  status = "",
  personId = "ID data!",
  location = null,
  logo = "",
  phoneNumber = "3287462378923",
}) => {
  const { getLocationByIds, locationCollection } = useHgLocations();
  const [locationInfo, setLocationInfo] = useState([]);

  const locationAsyncMethod = async (locationId) => {
    const loc = await getLocationByIds([locationId]);
    return loc;
  };

  useEffect(() => {
    locationAsyncMethod(location).then((res) => {
      // console.log(res);
      setLocationInfo(res);
    });
    // console.log(locationInfo);
  }, [location]);
  return (
    <div className={styles["container"]}>
      <Row className={styles["first-row"]}>
        <Col md={12} lg={6} className={styles["name-date-box"]}>
          <div className={styles["name-box"]}>
            {firstName} {lastName}
          </div>
        </Col>

        <Col md={12} lg={6} className={styles["action-box"]}>
          <div className={styles["date-box"]}>
            <Calendar04Icon className={styles["date-icon"]} />
            <span>
              {`${createDate?.getDate()}/${
                createDate?.getMonth() + 1
              }/${createDate?.getFullYear()}`}
              {createDate?.day}
            </span>
          </div>
        </Col>
      </Row>

      <div className={styles["third-row"]}>
        <div className={styles["first-col"]}>
          <div className={styles["title-col"]}>
            <div className={styles["middle-icons"]}>
              <Wallet03Icon className={styles["wallet-icon"]} />
            </div>
            <div className={styles["title"]}>Amount</div>
          </div>
          <div className={styles["value-col"]}>
            <span className={styles["value"]}>
              {/* {amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              })} */}
              {commaSeperatedCurrency(amount, 0)}
            </span>
          </div>
        </div>

        <div className={styles["seprator"]}></div>

        <div className={styles["middle-col"]}>
          <div className={styles["title-col"]}>
            <div className={styles["middle-icons"]}>
              <MedicineBottle01Icon className={styles["medical-icon"]} />
            </div>
            <div className={styles["title"]}>Treatment Type</div>
          </div>
          <div className={styles["value-col"]}>
            <div className={styles["value"]}>{treatmentType}</div>
          </div>
        </div>

        <div className={styles["seprator"]}></div>

        <div className={styles["last-col"]}>
          <div className={styles["title-col"]} style={{ paddingRight: 0 }}>
            <div className={styles["middle-icons"]}>
              <Payment02Icon className={styles["payment-icon"]} />
            </div>
            <div className={styles["title"]}>Prefered Monthly Payment</div>
          </div>
          <div className={styles["value-col"]}>
            <span className={styles["value"]}>
              {preferredMonthlyPayment !== 0
                ? commaSeperatedCurrency(preferredMonthlyPayment, 0)
                : "-"}
            </span>
          </div>
        </div>
      </div>

      <div className={styles["second-row"]}>
        <div className={styles["first-col"]}>
          {/* <img src={logo} alt="Company Logo" className={styles["logo"]} />{" "} */}
          <IdIcon className={styles["icon"]} />
          {(!!locationInfo &&
            locationInfo.length > 0 &&
            !!locationInfo[0] &&
            locationInfo[0].officeName) ||
            ""}
        </div>
        <div className={styles["second-col"]}></div>
        <div className={styles["third-col"]}>
          <Location01Icon className={styles["icon"]} />
          {!!locationInfo &&
            locationInfo.length > 0 &&
            !!locationInfo[0] &&
            `${locationInfo[0].address1},${locationInfo[0].city},${locationInfo[0].state} ${locationInfo[0].zipCode}`}
        </div>
      </div>

      <Row className={styles["last-row"]}>
        <Col className={styles["first-col"]}>
          <span className={styles["last-icons"]}>
            <Call02Icon className={styles["call-icon"]} />
          </span>
          <span className={styles["last-values"]}>{phoneNumber}</span>
        </Col>
        <Col className={styles["second-col"]}>
          <span className={styles["last-icons"]}>
            <Mail02Icon className={styles["email-icon"]} />
          </span>
          <span className={styles["last-values"]}>{emailAddress}</span>
        </Col>
      </Row>
    </div>
  );
};

export default LeadCard;
