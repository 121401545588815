export const mokeDataCollection = {
  data: {
    overviewCount: {
      lenderId: 20000,
      totalSubmittedCount: null,
      totalPreApprovedCount: null,
      totalLeadCount: null,
    },
    trendCollection: [
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 3,
        day: 17,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 2,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 3,
        day: 18,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 2,
        totalLeadCount: 2,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 3,
        day: 19,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 1,
        totalLeadCount: 1,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 3,
        day: 20,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 2,
        totalLeadCount: 1,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 4,
        day: 22,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 4,
        day: 25,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 1,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 4,
        day: 26,
        totalSubmittedCount: 4,
        totalPreApprovedCount: 4,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 1,
        week: 5,
        day: 29,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 6,
        day: 6,
        totalSubmittedCount: 52,
        totalPreApprovedCount: 42,
        totalLeadCount: 1,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 6,
        day: 9,
        totalSubmittedCount: 173,
        totalPreApprovedCount: 133,
        totalLeadCount: 77,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 11,
        totalSubmittedCount: 97,
        totalPreApprovedCount: 79,
        totalLeadCount: 79,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 12,
        totalSubmittedCount: 75,
        totalPreApprovedCount: 57,
        totalLeadCount: 57,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 13,
        totalSubmittedCount: 32,
        totalPreApprovedCount: 26,
        totalLeadCount: 26,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 14,
        totalSubmittedCount: 21,
        totalPreApprovedCount: 18,
        totalLeadCount: 18,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 15,
        totalSubmittedCount: 59,
        totalPreApprovedCount: 46,
        totalLeadCount: 45,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 16,
        totalSubmittedCount: 82,
        totalPreApprovedCount: 59,
        totalLeadCount: 55,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 7,
        day: 17,
        totalSubmittedCount: 83,
        totalPreApprovedCount: 63,
        totalLeadCount: 58,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 8,
        day: 19,
        totalSubmittedCount: 60,
        totalPreApprovedCount: 46,
        totalLeadCount: 44,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 8,
        day: 20,
        totalSubmittedCount: 68,
        totalPreApprovedCount: 48,
        totalLeadCount: 44,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 8,
        day: 22,
        totalSubmittedCount: 94,
        totalPreApprovedCount: 78,
        totalLeadCount: 66,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 8,
        day: 23,
        totalSubmittedCount: 144,
        totalPreApprovedCount: 115,
        totalLeadCount: 103,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 8,
        day: 24,
        totalSubmittedCount: 13,
        totalPreApprovedCount: 9,
        totalLeadCount: 7,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 9,
        day: 25,
        totalSubmittedCount: 32,
        totalPreApprovedCount: 28,
        totalLeadCount: 22,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 2,
        week: 9,
        day: 29,
        totalSubmittedCount: 13,
        totalPreApprovedCount: 11,
        totalLeadCount: 11,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 9,
        day: 1,
        totalSubmittedCount: 151,
        totalPreApprovedCount: 145,
        totalLeadCount: 128,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 9,
        day: 2,
        totalSubmittedCount: 60,
        totalPreApprovedCount: 57,
        totalLeadCount: 51,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 3,
        totalSubmittedCount: 29,
        totalPreApprovedCount: 28,
        totalLeadCount: 28,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 4,
        totalSubmittedCount: 3,
        totalPreApprovedCount: 3,
        totalLeadCount: 3,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 5,
        totalSubmittedCount: 10,
        totalPreApprovedCount: 10,
        totalLeadCount: 10,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 6,
        totalSubmittedCount: 11,
        totalPreApprovedCount: 11,
        totalLeadCount: 10,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 7,
        totalSubmittedCount: 45,
        totalPreApprovedCount: 45,
        totalLeadCount: 40,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 8,
        totalSubmittedCount: 11,
        totalPreApprovedCount: 11,
        totalLeadCount: 11,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 10,
        day: 9,
        totalSubmittedCount: 16,
        totalPreApprovedCount: 15,
        totalLeadCount: 13,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 11,
        day: 12,
        totalSubmittedCount: 42,
        totalPreApprovedCount: 40,
        totalLeadCount: 34,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 11,
        day: 13,
        totalSubmittedCount: 31,
        totalPreApprovedCount: 31,
        totalLeadCount: 29,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 11,
        day: 14,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 11,
        day: 15,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 32,
        totalLeadCount: 29,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 11,
        day: 16,
        totalSubmittedCount: 3,
        totalPreApprovedCount: 3,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 12,
        day: 17,
        totalSubmittedCount: 42,
        totalPreApprovedCount: 41,
        totalLeadCount: 36,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 12,
        day: 18,
        totalSubmittedCount: 26,
        totalPreApprovedCount: 26,
        totalLeadCount: 24,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 12,
        day: 19,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 1,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 12,
        day: 21,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 2,
        totalLeadCount: 2,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 13,
        day: 26,
        totalSubmittedCount: 17,
        totalPreApprovedCount: 17,
        totalLeadCount: 17,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 13,
        day: 29,
        totalSubmittedCount: 37,
        totalPreApprovedCount: 35,
        totalLeadCount: 29,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 13,
        day: 30,
        totalSubmittedCount: 15,
        totalPreApprovedCount: 13,
        totalLeadCount: 12,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 3,
        week: 14,
        day: 31,
        totalSubmittedCount: 2,
        totalPreApprovedCount: 2,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 14,
        day: 1,
        totalSubmittedCount: 51,
        totalPreApprovedCount: 51,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 14,
        day: 2,
        totalSubmittedCount: 114,
        totalPreApprovedCount: 114,
        totalLeadCount: 99,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 14,
        day: 6,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 66,
        totalLeadCount: 57,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 9,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 34,
        totalLeadCount: 30,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 14,
        day: 11,
        totalSubmittedCount: 101,
        totalPreApprovedCount: 101,
        totalLeadCount: 90,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 14,
        day: 13,
        totalSubmittedCount: 53,
        totalPreApprovedCount: 53,
        totalLeadCount: 47,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 15,
        totalSubmittedCount: 59,
        totalPreApprovedCount: 59,
        totalLeadCount: 55,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 16,
        totalSubmittedCount: 31,
        totalPreApprovedCount: 28,
        totalLeadCount: 26,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 17,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 52,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 18,
        totalSubmittedCount: 61,
        totalPreApprovedCount: 49,
        totalLeadCount: 41,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 19,
        totalSubmittedCount: 88,
        totalPreApprovedCount: 74,
        totalLeadCount: 65,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 15,
        day: 20,
        totalSubmittedCount: 90,
        totalPreApprovedCount: 78,
        totalLeadCount: 72,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 4,
        week: 16,
        day: 21,
        totalSubmittedCount: 109,
        totalPreApprovedCount: 99,
        totalLeadCount: 91,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 5,
        week: 17,
        day: 5,
        totalSubmittedCount: 240,
        totalPreApprovedCount: 240,
        totalLeadCount: 180,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 5,
        week: 19,
        day: 6,
        totalSubmittedCount: 300,
        totalPreApprovedCount: 290,
        totalLeadCount: 230,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 5,
        week: 22,
        day: 26,
        totalSubmittedCount: 20,
        totalPreApprovedCount: 18,
        totalLeadCount: 18,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 23,
        day: 6,
        totalSubmittedCount: 51,
        totalPreApprovedCount: 51,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 23,
        day: 7,
        totalSubmittedCount: 114,
        totalPreApprovedCount: 114,
        totalLeadCount: 99,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 23,
        day: 8,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 66,
        totalLeadCount: 57,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 24,
        day: 9,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 34,
        totalLeadCount: 30,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 24,
        day: 11,
        totalSubmittedCount: 101,
        totalPreApprovedCount: 101,
        totalLeadCount: 90,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 24,
        day: 13,
        totalSubmittedCount: 53,
        totalPreApprovedCount: 53,
        totalLeadCount: 47,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 24,
        day: 15,
        totalSubmittedCount: 59,
        totalPreApprovedCount: 59,
        totalLeadCount: 55,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 16,
        totalSubmittedCount: 31,
        totalPreApprovedCount: 28,
        totalLeadCount: 26,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 17,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 52,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 18,
        totalSubmittedCount: 61,
        totalPreApprovedCount: 49,
        totalLeadCount: 41,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 19,
        totalSubmittedCount: 88,
        totalPreApprovedCount: 74,
        totalLeadCount: 65,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 20,
        totalSubmittedCount: 90,
        totalPreApprovedCount: 78,
        totalLeadCount: 72,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 21,
        totalSubmittedCount: 109,
        totalPreApprovedCount: 99,
        totalLeadCount: 91,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 25,
        day: 22,
        totalSubmittedCount: 84,
        totalPreApprovedCount: 73,
        totalLeadCount: 67,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 23,
        totalSubmittedCount: 99,
        totalPreApprovedCount: 85,
        totalLeadCount: 73,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 24,
        totalSubmittedCount: 18,
        totalPreApprovedCount: 15,
        totalLeadCount: 13,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 25,
        totalSubmittedCount: 38,
        totalPreApprovedCount: 33,
        totalLeadCount: 28,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 26,
        totalSubmittedCount: 132,
        totalPreApprovedCount: 116,
        totalLeadCount: 97,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 27,
        totalSubmittedCount: 85,
        totalPreApprovedCount: 85,
        totalLeadCount: 75,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 28,
        totalSubmittedCount: 45,
        totalPreApprovedCount: 45,
        totalLeadCount: 40,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 6,
        week: 26,
        day: 29,
        totalSubmittedCount: 27,
        totalPreApprovedCount: 27,
        totalLeadCount: 24,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 27,
        day: 2,
        totalSubmittedCount: 79,
        totalPreApprovedCount: 79,
        totalLeadCount: 72,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 27,
        day: 3,
        totalSubmittedCount: 22,
        totalPreApprovedCount: 22,
        totalLeadCount: 18,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 27,
        day: 5,
        totalSubmittedCount: 78,
        totalPreApprovedCount: 78,
        totalLeadCount: 71,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 27,
        day: 6,
        totalSubmittedCount: 81,
        totalPreApprovedCount: 81,
        totalLeadCount: 72,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 28,
        day: 7,
        totalSubmittedCount: 76,
        totalPreApprovedCount: 76,
        totalLeadCount: 71,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 28,
        day: 8,
        totalSubmittedCount: 86,
        totalPreApprovedCount: 86,
        totalLeadCount: 80,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 28,
        day: 10,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 66,
        totalLeadCount: 57,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 28,
        day: 12,
        totalSubmittedCount: 94,
        totalPreApprovedCount: 94,
        totalLeadCount: 87,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 7,
        week: 30,
        day: 22,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 8,
        week: 34,
        day: 20,
        totalSubmittedCount: 650,
        totalPreApprovedCount: 600,
        totalLeadCount: 560,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 9,
        week: 39,
        day: 24,
        totalSubmittedCount: 750,
        totalPreApprovedCount: 700,
        totalLeadCount: 655,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 9,
        week: 39,
        day: 27,
        totalSubmittedCount: 1,
        totalPreApprovedCount: 1,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 9,
        week: 40,
        day: 30,
        totalSubmittedCount: 4,
        totalPreApprovedCount: 4,
        totalLeadCount: 0,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 40,
        day: 1,
        totalSubmittedCount: 91,
        totalPreApprovedCount: 91,
        totalLeadCount: 78,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 40,
        day: 2,
        totalSubmittedCount: 24,
        totalPreApprovedCount: 24,
        totalLeadCount: 18,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 40,
        day: 3,
        totalSubmittedCount: 30,
        totalPreApprovedCount: 29,
        totalLeadCount: 23,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 40,
        day: 4,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 32,
        totalLeadCount: 29,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 40,
        day: 5,
        totalSubmittedCount: 20,
        totalPreApprovedCount: 19,
        totalLeadCount: 17,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 6,
        totalSubmittedCount: 51,
        totalPreApprovedCount: 51,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 7,
        totalSubmittedCount: 114,
        totalPreApprovedCount: 114,
        totalLeadCount: 99,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 8,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 66,
        totalLeadCount: 57,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 9,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 34,
        totalLeadCount: 30,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 10,
        totalSubmittedCount: 35,
        totalPreApprovedCount: 34,
        totalLeadCount: 30,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 11,
        totalSubmittedCount: 101,
        totalPreApprovedCount: 101,
        totalLeadCount: 90,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 12,
        totalSubmittedCount: 101,
        totalPreApprovedCount: 101,
        totalLeadCount: 90,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 13,
        totalSubmittedCount: 53,
        totalPreApprovedCount: 53,
        totalLeadCount: 47,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 14,
        totalSubmittedCount: 53,
        totalPreApprovedCount: 53,
        totalLeadCount: 47,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 41,
        day: 15,
        totalSubmittedCount: 59,
        totalPreApprovedCount: 59,
        totalLeadCount: 55,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 16,
        totalSubmittedCount: 31,
        totalPreApprovedCount: 28,
        totalLeadCount: 26,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 17,
        totalSubmittedCount: 66,
        totalPreApprovedCount: 52,
        totalLeadCount: 48,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 18,
        totalSubmittedCount: 61,
        totalPreApprovedCount: 49,
        totalLeadCount: 41,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 19,
        totalSubmittedCount: 88,
        totalPreApprovedCount: 74,
        totalLeadCount: 65,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 20,
        totalSubmittedCount: 90,
        totalPreApprovedCount: 78,
        totalLeadCount: 72,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 21,
        totalSubmittedCount: 120,
        totalPreApprovedCount: 100,
        totalLeadCount: 98,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 22,
        totalSubmittedCount: 20,
        totalPreApprovedCount: 19,
        totalLeadCount: 19,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 42,
        day: 23,
        totalSubmittedCount: 20,
        totalPreApprovedCount: 19,
        totalLeadCount: 19,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 43,
        day: 24,
        totalSubmittedCount: 30,
        totalPreApprovedCount: 29,
        totalLeadCount: 27,
      },
      {
        lenderId: 20000,
        year: 2024,
        month: 10,
        week: 43,
        day: 25,
        totalSubmittedCount: 40,
        totalPreApprovedCount: 39,
        totalLeadCount: 37,
      },
    ],
  },
};
