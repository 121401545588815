const simplifiedApplicationStatus = {
  initiated: "Initiated",
  pendingApplicantSubmission: "PendingApplicantSubmission",
  submitted: "Submitted",
  processing: "Processing",
  approved: "Approved",
  noApproval: "NoApproval",
  error: "Error",
  applied: "Applied",
  abandoned: "Abandoned",
  getValueByText: (v) => {
    if (!v || typeof v !== "string" || v.length === 0) {
      return null;
    }
    switch (v.toLowerCase()) {
      default:
        return null;
      case "Initiated".toLocaleLowerCase():
        return simplifiedApplicationStatus.initiated;
      case "PendingApplicantSubmission".toLocaleLowerCase():
        return simplifiedApplicationStatus.pendingApplicantSubmission;
      case "Submitted".toLocaleLowerCase():
        return simplifiedApplicationStatus.submitted;
      case "Processing".toLocaleLowerCase():
        return simplifiedApplicationStatus.processing;
      case "Approved".toLocaleLowerCase():
        return simplifiedApplicationStatus.approved;
      case "NoApproval".toLocaleLowerCase():
        return simplifiedApplicationStatus.noApproval;
      case "Error".toLocaleLowerCase():
        return simplifiedApplicationStatus.error;
      case "Applied".toLocaleLowerCase():
        return simplifiedApplicationStatus.applied;
      case "Abandoned".toLocaleLowerCase():
        return simplifiedApplicationStatus.abandoned;
    }
  },
  collection: [
    { label: "Initiated", value: "Initiated" },
    { label: "Pending Applicant Submission", value: "PendingApplicantSubmission" },
    { label: "Submitted", value: "Submitted" },
    { label: "Processing", value: "Processing" },
    { label: "Approved", value: "Approved" },
    { label: "No Approval", value: "NoApproval" },
    { label: "Error", value: "Error" },
    { label: "Applied", value: "Applied" },
    { label: "Abandoned", value: "Abandoned" },
  ],
  getLabel: (v) => {
    const t = simplifiedApplicationStatus.getValueByText(v);
    if (!t) {
      return null;
    }
    return simplifiedApplicationStatus.collection.find((i) => i.value === t)?.label;
  },
};

export default simplifiedApplicationStatus;
