import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectAsyncControl from "./select-async-control";
import axios from "axios";
import { useHgLocations } from "../../hooks";
import "./select-control.scss";

const url = "/api/HealthcareGroup/GetOrganizationSiteListPaginated";

const HgSitesSelectControl = (props) => {
  const {
    onChange = () => {},
    menuPlacement = "auto",
    value = null,
    multi = false,
    className = "",
    isActive = undefined,
  } = props;
  const [innerValue, setInnerValue] = useState(value);

  const records = 10;
  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await axios.get(url, {
      params: {
        start: page < 0 ? 0 : (page - 1) * records,
        records,
        query,
        isActive,
      },
    });
    return {
      options: (response.data?.data?.hgSiteCollection || []).map((i) => ({
        ...i,
        name: i.nickname,
        value: i.id,
      })),
      hasMore:
        (response.data?.data?.totalCount || 0) >
        (response.data?.data?.hgSiteCollection || []).length,
      additional: {
        page: page + 1,
      },
    };
  };
  const {
    getLocationByIds,
    addLocations,
    deleteLocations,
    setUserType,
    locationCollection,
  } = useHgLocations();
  const getLocations = async (arrayOfIds) => {
    const loc = await getLocationByIds(arrayOfIds);
    return loc;
  };
  const innerChange = (o) => {
    setInnerValue(o);
    onChange(o);
  };
  useEffect(() => {
    // console.log(value);
    if (!value) {
      return;
    }
    getLocations(value).then((res) => {
      // console.log(user.locations);
      // console.log(res);
      const options = res.map(
        (i) =>
          i && {
            ...i,
            name: i?.nickname,
            value: i?.id,
          }
      );
      options.filter((i) => i != null && i);
      // console.log(options);
      setInnerValue(options);
    });
  }, [value]);
  return (
    <SelectAsyncControl
      loadOptions={loadOptions}
      onChange={innerChange}
      getOptionLabel={(o) => (!o ? "–" : `${o.name}`)}
      value={innerValue}
      getOptionValue={(o) => (!o ? null : o.id)}
      menuPlacement={menuPlacement}
      placeholder={"Locations"}
      multi={multi}
      className={className}
    />
  );
};
HgSitesSelectControl.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
  multi: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default HgSitesSelectControl;
