import styles from "./styles.module.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import {
  ArrowRight01Icon,
  ArrowLeftDoubleIcon,
  ArrowRightDoubleIcon,
  TaskDone01Icon,
  Home08Icon,
  Layout07Icon,
  FileDollarIcon,
  UserEdit01Icon,
  NoteIcon,
  Location04Icon,
  Agreement01Icon,
} from "hugeicons-react";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { useCommonContext } from "./common-context";
import { useLogout } from "./hooks";
import LoadingSpinner from "./components/loading-spinner/loading-spinner";

const Layout = ({ children = <></>, isLoading = false }) => {
  const [{ user, logo, sqLogo }, commonDispatch] = useCommonContext();
  const [isOpen, setIsOpen] = useState(true);
  const menuRef = useRef();
  const location = useLocation();
  const logout = useLogout();

  // user.data.userType = "Lender";
  // user.data.roleInOrganization = "";
  // user.data.userType = "HealthcareGroup";
  // user.data.roleInOrganization = "Administrator";
  // console.log(user.data.hg.name);

  const handleLogout = () => {
    logout()
      .then(() => {
        document.location.reload(1);
      })
      .catch(() => {});
  };

  // Function to handle outside clicks
  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Close the menu if the click is outside
    }
  };

  useEffect(() => {
    if (isOpen && window.innerWidth <= 1200) {
      // Attach event listener when menu is open
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      // Cleanup event listener when menu is closed or component is unmounted
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsOpen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wrapper">
      <Header
        // userLogoUrl={sqLogo.data?.url}
        user={user.data}
        // onLogout={handleLogout}
      />
      <div className={[styles["container"], "wrapper-content"].join(" ")}>
        <div
          className={`${styles["content"]} ${
            !isOpen && styles["closed-side-bar"]
          }`}
        >
          <LoadingSpinner isLoading={isLoading} />
          <div
            className={`${styles["content-base"]} ${
              !isOpen && styles["closed-side-bar"]
            }`}
          >
            {children}
          </div>
        </div>

        <div className={`${styles["side-bar"]} ${!isOpen && styles["closed"]}`}>
          {isOpen ? (
            <ArrowLeftDoubleIcon
              ref={menuRef}
              className={styles["menu-btn"]}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          ) : (
            <ArrowRightDoubleIcon
              className={styles["menu-btn"]}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          )}

          <div className={styles["logo-container"]}>
            <div className={styles["img-wrapper"]}>
              {!!logo.data?.url && logo.data?.url.length > 0 && (
                <img
                  src={logo.data?.url}
                  alt="logo"
                  className={`${styles["company-logo"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                />
              )}
            </div>

            <h3
              className={`${styles["company-name"]} ${
                !isOpen && styles["closed-side-bar"]
              }`}
            >
              {user.data?.hg?.name || user.data?.lender?.name}
            </h3>
          </div>
          {/* ///////////////////////////////////////////// */}

          <nav
            className={`${styles["navigation"]} ${
              !isOpen && styles["closed-side-bar"]
            }`}
          >
            <Link
              to="/home"
              className={`${styles["nav-list"]} ${
                (location.pathname === "/home" || location.pathname === "/") &&
                styles["active"]
              }`}
            >
              <Home08Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Home
              </div>
              <ArrowRight01Icon className={styles["icon"]} />
            </Link>

            {user.data.userType === "Lender" && (
              <Link
                to="/leads"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/leads" && styles["active"]
                }`}
              >
                <TaskDone01Icon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Leads
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {user.data.userType === "HealthcareGroup" && (
              <Link
                to="/applications"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/applications" && styles["active"]
                }`}
              >
                <NoteIcon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Applications
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {user.data.roleInOrganization === "Administrator" && (
              <Link
                to="/locations"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/locations" && styles["active"]
                }`}
              >
                <Location04Icon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Locations
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {user.data.roleInOrganization === "Administrator" && (
              <Link
                to="/billings"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/billings" && styles["active"]
                }`}
              >
                <FileDollarIcon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Billings
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {user.data.roleInOrganization === "Administrator" && (
              <Link
                to="/user-management"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/user-management" && styles["active"]
                }`}
              >
                <UserEdit01Icon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  User management
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {(user.data.userType === "Lender" ||
              (user.data.roleInOrganization === "Administrator" &&
                user.data.userType === "HealthcareGroup")) && (
              <Link
                to="/dashboard"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/dashboard" && styles["active"]
                }`}
              >
                <Layout07Icon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Dashboard
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}

            {user.data.userType === "HealthcareGroup" && (
              <Link
                to="/partners"
                className={`${styles["nav-list"]} ${
                  location.pathname === "/partners" && styles["active"]
                }`}
              >
                <Agreement01Icon className={styles["nav-icon"]} />
                <div
                  className={`${styles["nav-text"]} ${
                    !isOpen && styles["closed-side-bar"]
                  }`}
                >
                  Partners
                </div>
                <ArrowRight01Icon className={styles["icon"]} />
              </Link>
            )}
          </nav>
        </div>
      </div>

      <div className={styles["footer-navigation-wrapper"]}>
        <nav className={styles["footer-navigation"]}>
          <Link
            to="/home"
            className={`${styles["nav-list"]} ${
              (location.pathname === "/home" || location.pathname === "/") &&
              styles["active"]
            }`}
          >
            <Home08Icon className={styles["nav-icon"]} />
            <div className={styles["nav-text"]}>Home</div>
          </Link>

          {user.data.userType === "Lender" && (
            <Link
              to="/leads"
              className={`${styles["nav-list"]} ${
                location.pathname === "/leads" && styles["active"]
              }`}
            >
              <TaskDone01Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Leads
              </div>
            </Link>
          )}

          {user.data.userType === "HealthcareGroup" && (
            <Link
              to="/applications"
              className={`${styles["nav-list"]} ${
                location.pathname === "/applications" && styles["active"]
              }`}
            >
              <NoteIcon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Applications
              </div>
            </Link>
          )}

          {user.data.roleInOrganization === "Administrator" && (
            <Link
              to="/locations"
              className={`${styles["nav-list"]} ${
                location.pathname === "/locations" && styles["active"]
              }`}
            >
              <Location04Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Locations
              </div>
            </Link>
          )}

          {user.data.roleInOrganization === "Administrator" && (
            <Link
              to="/billings"
              className={`${styles["nav-list"]} ${
                location.pathname === "/billings" && styles["active"]
              }`}
            >
              <FileDollarIcon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Billings
              </div>
            </Link>
          )}

          {user.data.roleInOrganization === "Administrator" && (
            <Link
              to="/user-management"
              className={`${styles["nav-list"]} ${
                location.pathname === "/user-management" && styles["active"]
              }`}
            >
              <UserEdit01Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                {/* User management */}
                User-Mgmt
              </div>
            </Link>
          )}

          {(user.data.userType === "Lender" ||
            (user.data.roleInOrganization === "Administrator" &&
              user.data.userType === "HealthcareGroup")) && (
            <Link
              to="/dashboard"
              className={`${styles["nav-list"]} ${
                location.pathname === "/dashboard" && styles["active"]
              }`}
            >
              <Layout07Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Dashboard
              </div>
            </Link>
          )}

          {user.data.userType === "HealthcareGroup" && (
            <Link
              to="/partners"
              className={`${styles["nav-list"]} ${
                location.pathname === "/partners" && styles["active"]
              }`}
            >
              <Agreement01Icon className={styles["nav-icon"]} />
              <div
                className={`${styles["nav-text"]} ${
                  !isOpen && styles["closed-side-bar"]
                }`}
              >
                Partners
              </div>
            </Link>
          )}
        </nav>
      </div>

      <Footer />
    </div>
  );
};

Layout.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.oneOf(["admin", "user"]),
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
};

export default Layout;
