import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Layout from "../../layout";
import BillingCard from "../../components/billing-card";
import { useCommonContext } from "../../common-context";
import BillingsFilter from "../../components/filter-search/billings-filters";
import UseGetBillings from "../../hooks/use-get-billings";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination-control";
import { Container } from "react-bootstrap";

const Billings = () => {
  const [{ user }] = useCommonContext();
  const [billingsData, setBillingsData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [searchFilterData, setSearchFilterData] = useState({
    minAmount: 0,
    maxAmount: 20000,
    status: [],
    start: 0,
    Records: 10,
    organizationId: user.organizationId,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const getBillingsData = UseGetBillings();

  function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  }

  const handleNavigation = (startingIndex, records) => {
    const temp = _.cloneDeep(searchFilterData);
    temp.start = Math.floor(startingIndex / records) + 1;
    temp.Records = records;
    if (!_.isEqual(temp, searchFilterData)) {
      navigate(`/billings?start=${temp.start}&records=${temp.Records}`);
    }
  };

  useEffect(() => {
    let actionStatus = null;

    setLoadingStatus(apiLoadingStatus.loading);
    getBillingsData(searchFilterData)
      .then(([res, status]) => {
        actionStatus = status;
        setBillingsData(res);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        console.log(err);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMassage(actionStatus);
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "Unprocessable":
            showErrorMassage("Unprocessable");
            break;
          case "Locked":
            showErrorMassage("Locked");
            break;
          case "TooManyRequests":
            showErrorMassage("Too Many Requests ");
            break;
          case "OldVersionUpdate":
            showErrorMassage("Old Version Update");
            break;
          case "TransactionLock":
            showErrorMassage("Transaction Lock");
            break;
          case "Unauthorized":
            navigate("/login");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotFound":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            navigate("/login");
            break;
          case "Success":
            break;
        }
      });

    window.scrollTo(0, 0);
  }, [searchFilterData]);

  useEffect(() => {
    document.title = "Billings | DentiRate";
  }, []);

  return (
    <>
      <Layout isLoading={loadingStatus === apiLoadingStatus.loading}>
        <Container className={styles["container"]}>
          <BillingsFilter
            searchFilterData={searchFilterData}
            setSearchFilterData={setSearchFilterData}
          />
          {billingsData.map((billing, index) => (
            <BillingCard
              key={index}
              date={getFormattedDate(billing.issueDate)}
              paymentStatus={billing.invoiceStatus}
              numberOfLeads={billing.numberOfItems}
              amount={billing.amountDue}
              dueDate={getFormattedDate(billing.dueDate)}
            />
          ))}
          <Pagination
            start={(searchFilterData.start - 1) * searchFilterData.Records}
            records={searchFilterData.start}
            totalCount={257}
            onClick={handleNavigation}
            spanWidth={3}
            handleViewOptions={handleNavigation}
          />
        </Container>
      </Layout>
    </>
  );
};

export default Billings;
