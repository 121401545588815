import React, { useCallback, useEffect } from "react";
import { useCommonContext } from "./common-context/common-context-provider";
import { apiLoadingStatus } from "./infrastructure/constants/api";
import commonContextActions from "./common-context/common-context-actions";
import useGetUser from "./hooks/use-get-user";
import useGetLogo from "./hooks/use-get-logo";
import { initializeLocationStore } from "./stores";

const CommonContextActions = ({ children }) => {
  const [{ user, logo, sqLogo }, commonDispatch] = useCommonContext();
  const loadUser = useGetUser();
  const loadLogo = useGetLogo();
  const loadSqLogo = useGetLogo(true);

  // call the user endpoint

  useEffect(() => {
    if (user.loadingStatus === apiLoadingStatus.unloaded) {
      try {
        commonDispatch({
          type: commonContextActions.updateUserLoadingStatus,
          payload: apiLoadingStatus.loading,
        });
        loadUser()
          .then((u) => {
            commonDispatch({
              type: commonContextActions.updateUser,
              payload: {
                ...user,
                data: u,
                loadingStatus: apiLoadingStatus.loaded,
              },
            });
          })
          .catch((err) => {
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              commonDispatch({
                type: commonContextActions.updateUser,
                payload: {
                  ...user,
                  loadingStatus: apiLoadingStatus.unauthorized,
                },
              });
            } else {
              commonDispatch({
                type: commonContextActions.updateUser,
                payload: {
                  ...user,
                  loadingStatus: apiLoadingStatus.error,
                },
              });
            }
          });
      } catch {}
    }
  }, [user.loadingStatus]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/client_config.json");
        const data = await response.json();
        commonDispatch({
          type: commonContextActions.updateConfig,
          payload: data,
        });
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    // fetchConfig();
  }, []);

  useEffect(() => {
    if (
      user.loadingStatus === apiLoadingStatus.loaded &&
      logo.loadingStatus === apiLoadingStatus.unloaded &&
      !!user.data?.organizationId &&
      !!user.data?.userType
    ) {
      //load logo
      loadLogo({
        organizationId: user.data.organizationId,
        userType: user.data.userType,
      })
        .then((res) => {
          commonDispatch({
            type: commonContextActions.updateLogo,
            payload: {
              ...logo,
              data: res.data?.data,
              loadingStatus: apiLoadingStatus.loaded,
            },
          });
        })
        .catch((err) => {
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            commonDispatch({
              type: commonContextActions.updateLogo,
              payload: {
                ...logo,
                loadingStatus: apiLoadingStatus.unauthorized,
              },
            });
          } else {
            commonDispatch({
              type: commonContextActions.updateLogo,
              payload: {
                ...logo,
                loadingStatus: apiLoadingStatus.error,
              },
            });
          }
        });
    }

    if (
      user.loadingStatus === apiLoadingStatus.loaded &&
      sqLogo.loadingStatus === apiLoadingStatus.unloaded &&
      !!user.data?.organizationId &&
      !!user.data?.userType
    ) {
      //load square logo
      loadSqLogo({
        organizationId: user.data.organizationId,
        userType: user.data.userType,
      })
        .then((res) => {
          commonDispatch({
            type: commonContextActions.updateSqLogo,
            payload: {
              ...sqLogo,
              data: res.data?.data,
              loadingStatus: apiLoadingStatus.loaded,
            },
          });
        })
        .catch((err) => {
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            commonDispatch({
              type: commonContextActions.updateSqLogo,
              payload: {
                ...sqLogo,
                loadingStatus: apiLoadingStatus.unauthorized,
              },
            });
          } else {
            commonDispatch({
              type: commonContextActions.updateSqLogo,
              payload: {
                ...sqLogo,
                loadingStatus: apiLoadingStatus.error,
              },
            });
          }
        });
    }
  }, [user.loadingStatus]);

  useEffect(() => {
    initializeLocationStore(user.data?.userType);
  }, [user.loadingStatus]);

  if (user.loadingStatus === apiLoadingStatus.unloaded) {
    return <></>;
  }
  if (user.loadingStatus === apiLoadingStatus.error) {
    return <>Error</>;
  }

  return <>{children}</>;
};

CommonContextActions.propTypes = {};

export default CommonContextActions;
