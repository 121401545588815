import React from "react";
import styles from "./styles.module.scss";
import _ from "lodash";
import { Mail02Icon } from "hugeicons-react";

const InvitedUserItem = ({
  user = {
    id: "",
    firstName: "",
    lastName: "",
    invitationUsed: "",
    email: "",
  },
}) => {
  return (
    <>
      {/* <div>{user.isActive ? "true" : "false"}</div>
      <div>{checked ? "true" : "false"}</div> */}
      <div className={styles["container"]}>
        <div className={`${styles["row"]} ${styles["first"]}`}>
          <div className={styles["name-box"]}>
            {user.firstName} {user.lastName}
          </div>
          <div className={styles["date-box"]}>
            {/* <div
              className={`${styles["user-type"]} ${
                user.invitationUsed && styles["used"]
              }`}
            >
              {user.invitationUsed ? "Invitation Used" : "Invitation Not Used"}
            </div> */}
          </div>
        </div>

        <div className={styles["row-wrapper"]}>
          <div className={`${styles["second"]}`}>
            <span className={styles["icons"]}>
              <Mail02Icon className={styles["email-icon"]} />
            </span>
            <span className={styles["values"]}>{user.email}</span>
          </div>
        </div>
      </div>
    </>
  );
};

InvitedUserItem.propTypes = {};

export default InvitedUserItem;
