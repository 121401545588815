import styles from "./styles.module.scss";
import { useState } from "react";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import LoadingSpinner from "../../loading-spinner/loading-spinner";
import useDownloadKpi from "../../../hooks/use-download-kpi";
import { Container, Form } from "react-bootstrap";
import HgReportSelectControl from "../../show-data-control/hg-report-select-control";
import {
  HgLendersSelectControl,
  HgSitesSelectControl,
  LenderHGSiteSelectControl,
  LenderHGsSelectControl,
  UserSelectControl,
} from "../../select-control";
import _ from "lodash";

const DownloadReportControl = ({ filters = {} }) => {
  const options = ["User", "Location", "Lender"];
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.loaded);
  const [downloadData, setDownloadData] = useState({
    userType: filters.userType,
    startDate: filters.startDate,
    endDate: filters.endDate,
    timeZoneId: filters.timeZoneId,
    hgKpiType: filters.hgKpiType,
    siteIdCollection: [],
    userIdCollection: [],
    lenderIdCollection: [],
  });
  const [fileName, setFileName] = useState(
    `DentiRate-Details-KPI-${downloadData.startDate}-${downloadData.endDate}`
  );
  const [reportName, setReportName] = useState("");
  const downloadKpiReport = useDownloadKpi();
  const handleDownload = () => {
    setIsLoading(apiLoadingStatus.loading);

    // console.log(filters);
    // console.log(downloadData);
    const data = _.cloneDeep(downloadData);
    data.userIdCollection = downloadData.userIdCollection.map((i) => i.id);
    downloadKpiReport(data)
      .then((res) => {
        setIsLoading(apiLoadingStatus.loaded);
        // console.log(downloadData);
        // console.log(res);
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`); // Specify the file name and extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        setIsLoading(apiLoadingStatus.loaded);
        console.error("Error downloading the file", err);
      })
      .finally();
  };
  // console.log(filters.userType);
  // useEffect(() => {
  //   setDownloadData(filters);
  // }, [filters]);

  return (
    <Container className={styles["main-container"]}>
      <LoadingSpinner isLoading={isLoading === apiLoadingStatus.loading} />

      <div className={styles["title"]}>DOWNLOAD CENTER</div>

      <div className={styles["container"]}>
        <div className={styles["wrapper"]}>
          {/* <div className={styles["row"]}>
          <Form.Label className={styles["input-lable"]} htmlFor="firstName">
            Report name
          </Form.Label>
          <Form.Control
            type="text"
            id="reportName"
            // value={newApplication.firstName}
            onChange={(e) => {
              setReportName(e.target.value);
              setFileName(
                `${!!reportName && `${reportName}-`}DentiRate-Details-KPI-${
                  downloadData.startDate
                }-${downloadData.endDate}`
              );
              console.log(fileName);
            }}
            name="reportName"
            placeholder="Type report name here"
            disabled={isLoading === apiLoadingStatus.loading}
          />
        </div> */}
          <div className={styles["row"]}>
            {" "}
            <Form.Label className={styles["input-lable"]} htmlFor="selectUser">
              Select groups you need for report
            </Form.Label>
            {/* {filters.userType === "HealthcareGroup" && (
            <HgReportSelectControl
              id="selectUser"
              title=""
              // className={styles["controller"]}
              choice={
                options.find((i) => i === downloadData.hgKpiType) || options[0]
              }
              callbackfunction={(option) =>
                setDownloadData({
                  ...downloadData,
                  hgKpiType: option,
                })
              }
            />
          )} */}
            <div
              className={`${styles["flex-row"]} ${styles["first-flex-row"]}`}
            >
              <UserSelectControl
                className={styles["test"]}
                onChange={(value) => {
                  console.log(value);
                  setDownloadData({
                    ...downloadData,
                    userIdCollection: value ? [...value] : undefined,
                  });
                }}
                multi={true}
              />

              {filters.userType === "HealthcareGroup" && (
                <HgLendersSelectControl
                  className={styles["test"]}
                  onChange={(value) =>
                    setDownloadData({
                      ...downloadData,
                      lenderIdCollection: value ? value.id : undefined,
                    })
                  }
                  // multi={true}
                />
              )}
              {filters.userType === "HealthcareGroup" && (
                <HgSitesSelectControl
                  className={styles["test"]}
                  onChange={(value) => {
                    // console.log(value?.id);
                    // console.log(downloadData);
                    setDownloadData({
                      ...downloadData,
                      siteIdCollection: value ? value.id : undefined,
                    });
                  }}
                />
              )}
              {filters.userType === "Lender" && (
                <LenderHGsSelectControl
                  className={styles["test"]}
                  onChange={(value) => {
                    console.log(value?.id);
                    setDownloadData({
                      ...downloadData,
                      lenderIdCollection: value ? value.id : undefined,
                    });
                  }}
                />
              )}
              {filters.userType === "Lender" && (
                <LenderHGSiteSelectControl
                  className={styles["test"]}
                  onChange={(value) => {
                    console.log(value?.id);

                    setDownloadData({
                      ...downloadData,
                      siteIdCollection: value ? value.id : undefined,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles["flex-row"]}>
            <div className={styles["col"]}>
              <Form.Label className={styles["input-lable"]} htmlFor="startDate">
                From
              </Form.Label>
              <Form.Control
                className={styles["date-input"]}
                type="date"
                id="startDate"
                value={downloadData.startDate}
                onChange={(e) => {
                  setDownloadData({
                    ...downloadData,
                    startDate: e.target.value,
                  });
                  setFileName(
                    `${!!reportName && `${reportName}-`}DentiRate-Details-KPI-${
                      e.target.value
                    }-${downloadData.endDate}`
                  );
                }}
                name="doB"
                placeholder="mm/dd/yy"
                disabled={isLoading === apiLoadingStatus.loading}
              />
            </div>
            <div className={styles["col"]}>
              <Form.Label className={styles["input-lable"]} htmlFor="endDate">
                To
              </Form.Label>
              <Form.Control
                className={styles["date-input"]}
                type="date"
                id="endDate"
                value={downloadData.endDate}
                onChange={(e) => {
                  setDownloadData({ ...downloadData, endDate: e.target.value });
                  setFileName(
                    `${!!reportName && `${reportName}-`}DentiRate-Details-KPI-${
                      downloadData.startDate
                    }-${e.target.value}`
                  );
                }}
                name="doB"
                placeholder="mm/dd/yy"
                disabled={isLoading === apiLoadingStatus.loading}
              />
            </div>
          </div>

          <button onClick={handleDownload}>Create Report</button>
        </div>
      </div>
    </Container>
  );
};

export default DownloadReportControl;
