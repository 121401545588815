import React, { useState } from "react";
import styles from "./styles.module.scss";
import Form from "react-bootstrap/Form";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import FormCustomControl from "../form-custom-control";
import {
  MultiplicationSignCircleIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import {} from "hugeicons-react";
import PropTypes from "prop-types";
import _ from "lodash";

import { toast } from "react-toastify";
import MfaControl from "./mfa-control";

const LoginFormControl = ({
  handleLogin = () => {},
  onSuccessfulLogin = () => {},
}) => {
  const [loginFormData, setLoginFormData] = useState({
    loadingStatus: apiLoadingStatus.unloaded,
    email: "",
    password: "",
    reminder: false,
  });

  const [loginFormValidation, setLoginFormValidation] = useState({
    email: true,
    password: true,
    submit: true,
  });

  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [showPassword, setShowPassword] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [step, setStep] = useState(1);

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // console.log(email);
    return !!email && email !== "" && emailRegex.test(email);
  }

  const showErrorMessage = (message) => {
    toast(message, {
      type: "error",
      toastId: "login error",
    });
  };

  const validateForm = () => {
    ////////
    const emailValid = validateEmail(loginFormData.email);
    const passwordValid =
      !!loginFormData.password && loginFormData.password.length > 0;

    setLoginFormValidation({
      ...loginFormValidation,
      email: emailValid,
      password: passwordValid,
    });
    return emailValid && passwordValid;
  };

  const onLogin = () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    //validation here
    const validationResult = validateForm();
    if (!validationResult) {
      return;
    }
    //
    setLoginFormValidation({
      ...loginFormValidation,
      email: true,
    });
    // setShowWarning(false);
    setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = undefined;
    let data = undefined;
    handleLogin({
      email: loginFormData.email,
      password: loginFormData.password,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
        data = _.get(res, "data");
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            showErrorMessage("Unknown error");
            break;
          case "InvalidCredentials":
          case "InvalidCredentialsNewlyLockedout":
            showErrorMessage("Invalid credentials");
            break;

          // case "NotVerified":
          //   showErrorMessage("Account Not Verified");
          //   break;

          case "LockedOut":
            showErrorMessage("Your account is locked out.");
            break;
          case "AdminLockedOut":
            showErrorMessage("Your account was just locked out.");
            break;
          case "SuccessRequiresMFA":
            setStep(2);
            break;
          case "Success":
            onSuccessfulLogin({ newUser: data?.data?.membershipUser });
            break;
        }
      });
  };

  return (
    <div className={styles["container"]}>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />
      {step === 1 && (
        <FormCustomControl
          displayBackContinue={false}
          onContinue={onLogin}
          continueDisabled={loadingStatus === apiLoadingStatus.loading}
        >
          <Form.Group className="mb-3">
            <Form.Label className={styles["input-lable"]}>
              Email address
            </Form.Label>
            {/* <TooltipControl content={"Your Email"}> */}
            <Form.Control
              className={
                loginFormValidation.email
                  ? styles["input-text-box"]
                  : styles["input-text-box-warning"]
              }
              type="email"
              placeholder="Enter email"
              defaultValue={loginFormData.email || ""}
              onChange={(e) => {
                setLoginFormData({
                  ...loginFormData,
                  email: e.target.value,
                });
              }}
              disabled={loadingStatus === apiLoadingStatus.loading}
            />
            {!loginFormValidation.email && (
              <Form.Text className={styles["warning-error"]}>
                <MultiplicationSignCircleIcon /> Something went wrong!
              </Form.Text>
            )}

            {/* </TooltipControl> */}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className={styles["input-lable"]} htmlFor="password">
              Password
            </Form.Label>
            <div className={styles["input-container"]}>
              <Form.Control
                className={styles["input-text-box"]}
                id="password"
                type={!showPassword ? "password" : "text"}
                placeholder="Password"
                defaultValue={loginFormData.password || ""}
                onChange={(e) =>
                  setLoginFormData({
                    ...loginFormData,
                    password: e.target.value,
                  })
                }
                disabled={loadingStatus === apiLoadingStatus.loading}
              />
              <div
                className={styles["view-icon"]}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseUp={() => {
                  setShowPassword(!showPassword);
                }}
                onTouchStart={() => {
                  setShowPassword(!showPassword);
                }}
                onTouchEnd={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {!showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </div>
            </div>
          </Form.Group>
          <a className={styles["forgot-password"]} href="/recovery/init">
            Forgot your password?
          </a>
        </FormCustomControl>
      )}
      {step === 2 && (
        <MfaControl
          onSuccessfulLogin={onSuccessfulLogin}
          setLoadingStatus={setLoadingStatus}
          setStep={setStep}
        />
      )}
    </div>
  );
};

LoginFormControl.propTypes = {
  handleLogin: PropTypes.func,
  onSuccessfulLogin: PropTypes.func,
};

export default LoginFormControl;
