import React, { forwardRef, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import logo from "../../images/logo2.svg";
import {
  Facebook01Icon,
  InstagramIcon,
  Linkedin01Icon,
  NewTwitterIcon,
  NewTwitterRectangleIcon,
  TelegramIcon,
  TwitterSquareIcon,
  WhatsappIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["container"]}>
      <div className={styles["links-container"]}>
        <div className={styles["logos-container"]}>
          <img
            src={logo}
            alt="dentirate logo"
            onClick={() => {
              navigate("/");
            }}
          />

          <div
            className={`${styles["social-media-logos"]} ${styles["desktop-show"]}`}
          >
            {/* <a href="#">
              <Facebook01Icon
                className={styles["social-icon"]}
                color="white"
                size={18}
              />
            </a> */}
            <a
              href="https://www.linkedin.com/company/dentirate"
              target="_blank"
            >
              <Linkedin01Icon
                className={styles["social-icon"]}
                color="white"
                size={18}
              />
            </a>
            <a href="https://x.com/dentirate" target="_blank">
              <NewTwitterRectangleIcon
                className={styles["social-icon"]}
                color="white"
                size={18}
              />
            </a>
          </div>
        </div>
        <div className={styles["internal-links"]}>
          <div className={styles["col"]}>
            <a href="/home" target="_blank">
              Home
            </a>

            <a href="https://www.dentirate.com/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
            <a href="https://www.dentirate.com/terms/" target="_blank">
              Terms and Conditions
            </a>
          </div>
          <div className={styles["col"]}>
            <a href="https://www.dentirate.com/about-us/" target="_blank">
              About Us
            </a>
            <a href="https://www.dentirate.com/contact/" target="_blank">
              Contact Us
            </a>
            {/* <a href="/profile" target="_blank">
              User Profile
            </a> */}
          </div>
        </div>

        <div
          className={`${styles["social-media-logos"]} ${styles["mobile-show"]}`}
        >
          {/* <a href="#">
            <Facebook01Icon
              className={styles["social-icon"]}
              color="white"
              size={18}
            />
          </a>
          <a href="#">
            <WhatsappIcon
              className={styles["social-icon"]}
              color="white"
              size={18}
            />
          </a>
          <a href="#">
            <InstagramIcon
              className={styles["social-icon"]}
              color="white"
              size={18}
            />
          </a> */}
          <a href="https://www.linkedin.com/company/dentirate" target="_blank">
            <Linkedin01Icon
              className={styles["social-icon"]}
              color="white"
              size={18}
            />
          </a>
          <a href="https://x.com/dentirate" target="_blank">
            <NewTwitterRectangleIcon
              className={styles["social-icon"]}
              color="white"
              size={18}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
