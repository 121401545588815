import React from "react";

import UserItem from "../../components/user-item";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import InvitedUserItem from "../../components/invited-user-item";
import Pagination from "../../components/pagination-control/pagination";

const InvitationsTab = ({
  invitedUsers = { totalCount: 0, invitedUsersCollection: [] },
  loadingStatus,
  start,
  records,
  handlePagination = () => {},
}) => {
  // console.log(invitedUsers);
  // console.log(InvitationsData);

  // return <></>;
  return (
    <>
      {(invitedUsers?.invitedUsersCollection || []).map((user, index) => {
        // console.log(user);
        return <InvitedUserItem key={index} user={user} />;
      })}
      {loadingStatus === apiLoadingStatus.loaded &&
        (invitedUsers?.totalCount || 0) === 0 && (
          <div className={styles["no-user"]}>No invitation exists!</div>
        )}

      {invitedUsers?.totalCount > records && (
        <Pagination
          start={start}
          records={records}
          spanWidth={5}
          totalCount={invitedUsers?.totalCount}
          onClick={(value) => handlePagination(value)}
          displayViewOptions={false}
          handleViewOptions={
            (records) => {}
            // handleFilterChange({
            //   ...searchFilterData,
            //   Records: records,
            // })
          }
          // className={styles["pagination"]}
        />
      )}
    </>
  );
};
InvitationsTab.propTypes = {
  invitedUsers: PropTypes.shape({
    totalCount: PropTypes.number,
    invitedUsersCollection: PropTypes.arrayOf(PropTypes.any), // TODO:
  }),
  loadingStatus: PropTypes.oneOf(Object.values(apiLoadingStatus)),
};
export default InvitationsTab;
