import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Layout from "../../layout";
import { useCommonContext } from "../../common-context";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import PartnerCard from "../../components/partners-card";
import axios from "axios";

const Partners = () => {
  const [{ user, logo }] = useCommonContext();
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [partnersCollection, setPartnersCollection] = useState([]);
  const [logoCollection, setLogoCollection] = useState([]);
  const navigate = useNavigate();
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  // console.log(logoCollection);
  useEffect(() => {
    setLoadingStatus(apiLoadingStatus.loading);
    let actionStatus = null;
    axios
      .get(
        "/api/HealthcareGroup/GetHgLendersPaginated?start=0&records=1000&api-version=1.0"
      )
      .then((res) => {
        setPartnersCollection(res.data?.data?.lenderCollection);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        console.log(err);
      })
      .finally(() => {
        switch (actionStatus) {
          case "Unauthorized":
            navigate("/login");
            break;
        }
        setLoadingStatus(apiLoadingStatus.loaded);
      });
  }, []);

  useEffect(() => {
    if (!partnersCollection.length > 0) {
      return;
    }
    const requestCollection = partnersCollection.map((partner) => ({
      organizationId: partner.id,
      organizationType: "lender",
    }));
    axios.post("/api/Organization/Logos", { requestCollection }).then((res) => {
      // console.log(
      //   res.data?.data?.logoCollection[0]?.logoCollection[1]?.imageCdnUrl
      // );
      setLogoCollection(
        res.data?.data?.logoCollection?.map(
          (collection) => collection.logoCollection[1]?.imageCdnUrl
        )
      );
    });
  }, [partnersCollection]);

  return (
    <>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />
      <Layout>
        <div className={styles["container"]}>
          {partnersCollection.map((partner, index) => (
            <PartnerCard
              key={index}
              logoUrl={logoCollection[index]}
              companyName={partner.name}
              website={partner.website}
            />
          ))}
        </div>
      </Layout>
    </>
  );
};

export default Partners;
