import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import styles from "./styles.module.scss";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import FormCustomControl from "../../components/form-custom-control";
import { useQuery, useUpdatePassword } from "../../hooks";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  AlertCircleIcon,
  CheckmarkCircle02Icon,
  MultiplicationSignCircleIcon,
  ViewIcon,
  ViewOffIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";

const ChangePasswordControl = ({ setStep = () => {}, userId = "" }) => {
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  // const [matchPasswords, setMatchPasswords] = useState(null);
  const [minCharactersPass, setMinCharactersPass] = useState(null);
  const [upperCasePass, setUpperCasePass] = useState(null);
  const [minNumPass, setMinNumPass] = useState(null);
  const [symbolFormatPass, setSymbolFormatPass] = useState(null);
  const navigate = useNavigate();

  const [showConfirmPassword, setShowConfirmPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  // const [passwordValidation, setPasswordValidation] = useState({
  //   matchPasswords: true,
  //   minCharactersPass: true,
  //   upperCasePass: true,
  //   minNumPass: true,
  //   symbolFormatPass: true,
  // });
  const [message, setMessage] = useState("");
  let actionStatus = undefined;
  const queryHelper = useQuery();
  const changePassword = useUpdatePassword();

  const showErrorMassage = (massage) => {
    toast(message, { type: "error", autoClose: 4000, toastId: "error" });
  };

  const validatePassword = (event) => {
    const symbolFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (event.target.value.length < 8) {
      setMinCharactersPass(false);
    } else {
      setMinCharactersPass(true);
    }
    if (!/\d/.test(event.target.value)) {
      // console.log("setminNumPass", !/\d/.test("passw4ord.newPassword"));
      setMinNumPass(false);
    } else {
      setMinNumPass(true);
    }
    if (event.target.value !== event.target.value.toLowerCase()) {
      setUpperCasePass(true);
    } else {
      setUpperCasePass(false);
    }
    if (!symbolFormat.test(event.target.value)) {
      setSymbolFormatPass(false);
    } else {
      setSymbolFormatPass(true);
    }
  };

  const onContinue = () => {
    // validatePassword();
    if (
      password.newPassword === "" ||
      !minCharactersPass ||
      !minNumPass ||
      !upperCasePass ||
      !symbolFormatPass ||
      password.newPassword !== password.confirmPassword
    ) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);

    changePassword({
      userId: userId,
      oldPassword: password.oldPassword,
      newPassword: password.confirmPassword,
    })
      .then((res) => {
        actionStatus = _.get(res, "headers.action-status", null);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.loaded);
        switch (actionStatus) {
          default:
            setMessage("Unknown error");
            setStep("Success");
            break;
          case "Unauthorized":
            navigate("/login");
            break;
          case "Failed":
          case "ValidationError":
            setMessage("New Password is Invalid");
            setStep("Success");
            break;
          case "NotExist":
            setMessage("Link does not exists");
            setStep("Success");
            break;
          case "Expired":
            setMessage("link has been expiered");
            setStep("Success");
            break;
          case "NoPermission":
            setMessage("Your have no permisson to change password.");
            setStep("Success");
            break;
          case "Used":
            setMessage("This password has been already used");
            setStep("Success");
            break;
          case "Success":
            setMessage("Your password has been successfully changed!");
            setStep("Success");
            break;
        }
      });
  };

  return (
    <div className={styles["main"]}>
      <LoadingSpinner isLoading={loadingStatus === apiLoadingStatus.loading} />

      <Container className={styles["container"]}>
        <Row className={styles["welcome-container"]}>
          <Col>
            <h6>Change Password</h6>
          </Col>
        </Row>
        <FormCustomControl
          onContinue={onContinue}
          onBack={() => setStep("profile")}
        >
          <Form.Group className="mb-3">
            <Form.Label className={styles["input-lable"]}>
              Current Password
            </Form.Label>
            <div className={styles["input-container"]}>
              <Form.Control
                className={styles["input-text-box"]}
                type={showConfirmPassword.oldPassword ? "text" : "password"}
                placeholder="password"
                defaultValue={password.oldPassword || ""}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    oldPassword: e.target.value,
                  });
                }}
                disabled={loadingStatus === apiLoadingStatus.loading}
              />
              <div
                className={styles["view-icon"]}
                onMouseDown={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    oldPassword: true,
                  });
                }}
                onMouseUp={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    oldPassword: false,
                  });
                }}
                onTouchStart={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    oldPassword: true,
                  });
                }}
                onTouchEnd={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    oldPassword: false,
                  });
                }}
              >
                {!showConfirmPassword.oldPassword ? (
                  <ViewIcon />
                ) : (
                  <ViewOffIcon />
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className={styles["input-lable"]}>
              New Password
            </Form.Label>
            <div className={styles["input-container"]}>
              <Form.Control
                className={
                  (minCharactersPass == null &&
                    minNumPass == null &&
                    upperCasePass == null &&
                    symbolFormatPass == null) ||
                  (minCharactersPass &&
                    minNumPass &&
                    upperCasePass &&
                    symbolFormatPass)
                    ? styles["input-text-box"]
                    : styles["input-text-box-warning"]
                }
                type={showConfirmPassword.newPassword ? "text" : "password"}
                placeholder="password"
                defaultValue={password.newPassword || ""}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    newPassword: e.target.value,
                  });
                  validatePassword(e);
                }}
                disabled={loadingStatus === apiLoadingStatus.loading}
              />
              <div
                className={styles["view-icon"]}
                onMouseDown={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: true,
                  });
                }}
                onMouseUp={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: false,
                  });
                }}
                onTouchStart={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: true,
                  });
                }}
                onTouchEnd={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: false,
                  });
                }}
              >
                {!showConfirmPassword.newPassword ? (
                  <ViewIcon />
                ) : (
                  <ViewOffIcon />
                )}
              </div>
            </div>
            <Form.Text
              className={`${styles["hint"]} ${
                minCharactersPass === true && styles["checked"]
              } ${minCharactersPass === false && styles["warning-error"]}`}
            >
              {minCharactersPass === null && <AlertCircleIcon />}
              {minCharactersPass === true && <CheckmarkCircle02Icon />}
              {minCharactersPass === false && <MultiplicationSignCircleIcon />}
              At least 8 Characters
            </Form.Text>

            <Form.Text
              className={`${styles["hint"]} ${
                upperCasePass === true && styles["checked"]
              } ${upperCasePass === false && styles["warning-error"]}`}
            >
              {upperCasePass === null && <AlertCircleIcon />}
              {upperCasePass === true && <CheckmarkCircle02Icon />}
              {upperCasePass === false && <MultiplicationSignCircleIcon />} At
              least one Uppercase
            </Form.Text>

            <Form.Text
              className={`${styles["hint"]} ${
                symbolFormatPass === true && styles["checked"]
              } ${symbolFormatPass === false && styles["warning-error"]}`}
            >
              {symbolFormatPass === null && <AlertCircleIcon />}
              {symbolFormatPass === true && <CheckmarkCircle02Icon />}
              {symbolFormatPass === false && (
                <MultiplicationSignCircleIcon />
              )}{" "}
              At least one Symbol
            </Form.Text>

            <Form.Text
              className={`${styles["hint"]} ${
                minNumPass === true && styles["checked"]
              } ${minNumPass === false && styles["warning-error"]}`}
            >
              {minNumPass === null && <AlertCircleIcon />}
              {minNumPass === true && <CheckmarkCircle02Icon />}
              {minNumPass === false && <MultiplicationSignCircleIcon />} At
              least one Number
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className={styles["input-lable"]}>
              Re-enter new password
            </Form.Label>
            <div className={styles["input-container"]}>
              <Form.Control
                className={
                  password.confirmPassword === "" ||
                  password.confirmPassword === password.newPassword
                    ? styles["input-text-box"]
                    : styles["input-text-box-warning"]
                }
                type={showConfirmPassword.confirmPassword ? "text" : "password"}
                placeholder="Re-enter new password"
                defaultValue={password.confirmPassword || ""}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    confirmPassword: e.target.value,
                  });
                }}
                disabled={loadingStatus === apiLoadingStatus.loading}
              />
              <div
                className={styles["view-icon"]}
                onMouseDown={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    confirmPassword: true,
                  });
                }}
                onMouseUp={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    confirmPassword: false,
                  });
                }}
                onTouchStart={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: true,
                  });
                }}
                onTouchEnd={() => {
                  setShowConfirmPassword({
                    ...showConfirmPassword,
                    newPassword: false,
                  });
                }}
              >
                {!showConfirmPassword.confirmPassword ? (
                  <ViewIcon />
                ) : (
                  <ViewOffIcon />
                )}
              </div>
            </div>
            {password.newPassword !== password.confirmPassword &&
              password.confirmPassword !== "" && (
                <Form.Text
                  className={`${styles["hint"]} ${styles["warning-error"]}`}
                >
                  <MultiplicationSignCircleIcon />
                  Passwords don't matched!
                </Form.Text>
              )}
          </Form.Group>
        </FormCustomControl>
      </Container>
    </div>
  );
};

export default ChangePasswordControl;
