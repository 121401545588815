import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { MultiplicationSignCircleIcon } from "hugeicons-react";

const ValidationWrapper = ({
  isValid = true,
  children = <></>,
  title = null,
  titleStar = false,
  inputId = "",
  errorMessage = "",
  alertStyle = {},
  wrapperStyle = {},
}) => {
  return (
    <div>
      <div
        className={`${styles["input-wrapper"]} ${
          isValid ? "" : "invalid-input"
        }`}
        style={wrapperStyle}
      >
        {!!title && (
          <label htmlFor={inputId} className={styles["input-title"]}>
            {title}
            {titleStar && <span> *</span>}
          </label>
        )}
        <>{children}</>
      </div>
      <div
        // className={`alert alert-danger validation-message ${
        //   isValid ? "invisible" : ""
        // } `}
        style={alertStyle}
        className={`alert ${styles["warning-error"]} ${
          isValid ? "invisible" : ""
        } `}
      >
        <div className={styles["icon"]}>
          <MultiplicationSignCircleIcon />
        </div>
        {errorMessage}
      </div>
    </div>
  );
};

ValidationWrapper.propTypes = {
  children: PropTypes.node,
};

export default ValidationWrapper;
