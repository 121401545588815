import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Logo from "./logo";
import ConsentItem from "./consent-item";
import ValidatorMessage from "../../components/validation-wrapper/validator-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBox from "../../components/check-box/check-box";
import { Alert01Icon } from "hugeicons-react";

const ApplicantConsent = ({ onConsent, lenderCollection = [] }) => {
  const intl = useIntl();

  const [agreeValid, setAgreeValid] = useState(true);
  const [allChecked, setAllChecked] = useState(false);
  const [lenderItemChecked, setLenderItemChecked] = useState([]);
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const validateCheckboxes = () => {
    setAgreeValid(allChecked);
    return allChecked;
  };
  const innerClick = () => {
    const isValid = validateCheckboxes();

    if (isValid) {
      onConsent();
    }
  };

  useEffect(() => {
    setLenderItemChecked([
      ...(lenderCollection.length === 0
        ? []
        : Array(lenderCollection.length).fill(false)),
    ]);
  }, [lenderCollection]);

  useEffect(() => {
    setAgreeValid(true);
    if (!lenderItemChecked.reduce((pre, cur) => pre && cur, true)) {
      setAllChecked(false);
    }
  }, [lenderItemChecked]);

  //
  const onChangeItems = () => {
    // let result = true;
    // checkboxes.forEach((status) => {
    //   !status && (result = false);
    // });
    // setAgree(result && checkbox);
    // setAgreeValid(result && checkbox);
  };
  // useEffect(() => {
  //   let result = true;
  //   checkboxes.forEach((status) => {
  //     !status && (result = false);
  //   });
  //   setAgree(result && checkbox);
  //   setAgreeValid(result && checkbox);
  // }, [checkboxes, checkbox]);
  return (
    <div className={`${styles["container"]} ${styles["consent-container"]}`}>
      <div>
        <Logo />
        <div
          className={styles["welcome-message"]}
          style={{ marginTop: "45px" }}
        >
          {getIntlMessage("Consent.WeNeedYourConsent")}
        </div>
        {!!lenderCollection && lenderCollection.length > 0 && (
          <>
            <p className={styles["second-message"]}>
              <FormattedMessage
                id="Consent.ReadLenderPartners"
                values={{
                  terms: (chunks) => (
                    <span className="highlighted">{chunks}</span>
                  ),
                  partners: (chunks) => (
                    <span className="highlighted">{chunks}</span>
                  ),
                }}
              />
            </p>
            <button className={styles["application-detail"]}>
              Application Detail
            </button>
          </>
        )}
        <div className="form">
          <ul className={styles["policy-list"]}>
            {lenderCollection.map((item, index) => (
              <li key={index}>
                <ConsentItem
                  lenderName={item.lenderName}
                  lenderLink={item.website}
                  privacyPolicy={item.privacyPolicy}
                  termsConditions={item.termsConditions}
                  checked={lenderItemChecked[index]}
                  onClick={() => {
                    let result = [...lenderItemChecked];
                    result[index] = !result[index];
                    setLenderItemChecked([...result]);
                  }}
                  onchange={onChangeItems}
                  lastItem={index === lenderCollection.length - 1}
                />
              </li>
            ))}
          </ul>
          <div className={styles["agree-container"]}>
            <CheckBox
              onClick={(e) => {
                let checkboxesArrayTemp = [...lenderItemChecked];
                checkboxesArrayTemp = checkboxesArrayTemp.map((item) => {
                  return e.target.checked;
                });
                setLenderItemChecked(checkboxesArrayTemp);
                setAllChecked(e.target.checked);
              }}
              Checked={allChecked}
              onchange={onChangeItems}
              style={{ marginTop: "2px" }}
            />
            {/* <input
            type="checkbox"
            onClick={(e) => {
              setAgreeValid(!agree);
              setAgree(!agree);
            }}
            // className={`${styles["agree-check"]} ${
            //   agreeValid ? "" : "input-invalid"
            // }`}
            id="agree-chk"
          /> */}
            <label htmlFor="agree-chk" className={styles["agree-label"]}>
              <FormattedMessage
                id="Consent.Agreement"
                values={{
                  terms: (chunks) => (
                    <a
                      className="link_primary fw_bold"
                      href="https://www.dentirate.com/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks} <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  ),
                  privacy: (chunks) => (
                    <a
                      className="link_primary fw_bold"
                      href="https://www.dentirate.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks} <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  ),
                }}
              />
            </label>
          </div>
          <div style={{ display: "flex", height: "56px" }}>
            {!agreeValid && (
              <Alert01Icon
                height="56px"
                color="#cc2929"
                strokeWidth={2}
                size={20}
              />
            )}
            <ValidatorMessage
              errorMessage={getIntlMessage("Errors.Validation.Consent")}
              isValid={agreeValid}
              style={{
                minHeight: "56px",
                padding: "8px",
                margin: "12px 0 24px 0",
                border: "none",
                background: "none",
                color: "#cc2929",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "16px",
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "right", marginTop: "24px" }}>
        <button
          className="btn btn-primary mt_3"
          style={{ width: "70%" }}
          onClick={innerClick}
        >
          {getIntlMessage("IAgree")}
        </button>
      </div>
    </div>
  );
};

ApplicantConsent.propTypes = {};

export default ApplicantConsent;
