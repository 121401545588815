import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";
import Login from "./pages/login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/home";
import Leads from "./pages/leads";
import UserManagement from "./pages/user-management";
import Billings from "./pages/bilings/bilings";
import AddUser from "./pages/add-user/add-user";
import { CommonContextProvider } from "./common-context";
import CommonContextActions from "./context-actions";
import CheckAuthorization from "./check-auhtorization";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RecoveryInit from "./pages/recovery/init";
import ChangePassword from "./pages/recovery/change/change";
import Application from "./pages/applications/my-applications";
import Test from "./pages/test";
import CreateUser from "./pages/create-user/create-user";
import ApplicationForm from "./pages/applications/form/form";
import Applicant from "./pages/applicant";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Locations from "./pages/locations";
import ProfilePage from "./pages/profile/profile.jsx";
import Partners from "./pages/partners/partners.jsx";
import NewLocationForm from "./pages/locations/form/form.jsx";
import PaymentForm from "./pages/applicant/payment-form.jsx";
import Localize from "./localize.jsx";
import EditUser from "./pages/add-user/edit-user.jsx";
import UserTypeSelection from "./components/login-form/userTyoe-selection.jsx";
import Help from "./pages/help/help.jsx";

function App() {
  return (
    <CommonContextProvider>
      <CommonContextActions>
        <Localize>
          <ToastContainer
            position="top-center"
            autoClose={4000}
            theme="light"
            hideProgressBar={false}
          />
          <Router>
            <CheckAuthorization>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/test" element={<Test />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/billings" element={<Billings />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/payment" element={<PaymentForm />} />
                <Route path="/invitation" element={<CreateUser />} />
                <Route path="/userType" element={<UserTypeSelection />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route
                  path="/user-management/invite"
                  element={<CreateUser />}
                />
                <Route
                  path="/user-management/edit/:userId"
                  element={<EditUser />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/applications" element={<Application />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/application/:applicationId"
                  element={<Applicant />}
                />
                <Route path="/applications/new" element={<ApplicationForm />} />
                <Route
                  path="/applications/edit/:applicationId"
                  element={<ApplicationForm title="Edit Application" />}
                />
                <Route path="/locations/new" element={<NewLocationForm />} />
                <Route
                  path="/locations/edit/:locationId"
                  element={<NewLocationForm title="Edit Location" />}
                />

                <Route path="/recovery/init" element={<RecoveryInit />} />
                <Route path="/recovery/open" element={<ChangePassword />} />
                <Route path="/help" element={<Help />} />
              </Routes>
            </CheckAuthorization>
          </Router>
        </Localize>
      </CommonContextActions>
    </CommonContextProvider>
  );
}

export default App;
