import { Container, Table } from "react-bootstrap";
import styles from "./styles.module.scss";
import submittedIcon from "../../../images/icons/Submitted-Icon.svg";
import preApproved from "../../../images/icons/pre-approved-icon.svg";
import prospects from "../../../images/icons/Prospect-Icon.svg";
import countIcon from "../../../images/icons/Count-Icon.svg";
import rate1 from "../../../images/icons/Rate1-Icon.svg";
import amount from "../../../images/icons/amount-Icon.svg";
import avgAmount from "../../../images/icons/Average-Icon.svg";
import rate2 from "../../../images/icons/Rate2-Icon.svg";
import { useEffect, useState } from "react";
import {
  commaSeperatedCurrency,
  commaSeperatedCurrencyKMB,
  commaSeperatedDecimal,
} from "../../../infrastructure/helpers/number-helpers";
import apiHelperMethods from "../../../infrastructure/helpers/api-helpers";
import useGetKpiSummary from "../../../hooks/use-get-kpi-summary";
import { apiLoadingStatus } from "../../../infrastructure/constants/api";
import LoadingSpinner from "../../loading-spinner/loading-spinner";
import { toast } from "react-toastify";
import _ from "lodash";
import ActiveUsersControl from "../active-users-control/active-users-control";
import { TaskDone01Icon } from "hugeicons-react";
import { useNavigate } from "react-router-dom";

const SummaryControl = ({
  filters = {},
  showSpinner = true,
  showActiveUsers = true,
}) => {
  const [isLoading, setIsLoading] = useState(apiLoadingStatus.loaded);
  const [summaryData, setSummaryData] = useState({
    healthCareId: 20000,
    totalSubmittedCount: null,
    totalPreApprovedCount: null,
    totalLeadCount: null,
    preApprovedRate: null,
    leadRate: null,
    totalSubmittedAmount: null,
    totalPreApprovedAmount: null,
    totalLeadAmount: null,
    avgSubmittedAmount: null,
    avgPreApprovedAmount: null,
    avgLeadAmount: null,
    preApprovedAmtRate: null,
    leadAmtRate: null,
  });
  const showErrorMassage = (massage) => {
    toast(massage, { type: "error", autoClose: 4000, toastId: "error" });
  };
  const navigate = useNavigate();
  const getSummary = useGetKpiSummary();
  useEffect(() => {
    // return;
    let actionStatus = null;
    setIsLoading(apiLoadingStatus.loading);
    getSummary(filters)
      .then(([res, status]) => {
        setIsLoading(apiLoadingStatus.loaded);
        actionStatus = status;
        setSummaryData(res);
      })
      .catch((err) => {
        actionStatus = _.get(err, "response.headers.action-status", null);
        setIsLoading(apiLoadingStatus.error);
        // console.log(err);
      })
      .finally(() => {
        switch (actionStatus) {
          case "Unauthorized":
            navigate("/login");
            break;
          case "Failed":
            showErrorMassage("Failed");
            break;
          case "UsedBefore":
            showErrorMassage("Used Before");
            break;
          case "SameAsPrevious":
            showErrorMassage("Same As Previous");
            break;
          case "AdminLockedOut":
            showErrorMassage("Admin LockedOut ");
            break;
          case "InvalidCredentialsNewlyLockedOut":
            showErrorMassage("Invalid Credentials Newly LockedOut");
            break;
          case "InvalidCredentials":
            showErrorMassage("Invalid Credentials");
            break;
          case "ValidationError":
            showErrorMassage("Validation Error");
            break;
          case "NotExists":
            showErrorMassage("Not Found");
            break;
          case "Forbidden":
            showErrorMassage("Forbidden ");
            break;
          case "Success":
            break;
        }
      });
  }, [filters]);

  return (
    <Container className={styles["container"]}>
      <LoadingSpinner
        isLoading={isLoading === apiLoadingStatus.loading && showSpinner}
      />
      {showActiveUsers && <ActiveUsersControl filters={filters} />}

      <table className={styles["table"]}>
        <thead className={styles["table-header"]}>
          <tr>
            <th></th>
            <th>
              <div className={styles["title"]}>
                <img src={submittedIcon} alt="submitted Icon" />
                {/* <TaskDone01Icon /> */}
                <span>Submitted</span>
              </div>
            </th>
            <th>
              <div className={styles["title"]}>
                <img src={preApproved} alt="pre Approved Icon" />
                <span>Pre-approved</span>
              </div>
            </th>
            <th>
              <div className={styles["title"]}>
                <img src={prospects} alt="prospects Icon" />
                <span>Prospects</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={styles["table-body"]}>
          <tr className={styles["table-body"]}>
            <td>
              <div className={styles["title"]}>
                <img src={countIcon} alt="count Icon" />
                <span>Count</span>
              </div>
            </td>
            <td className={`${styles["amount"]} ${styles["submitted"]}`}>
              {summaryData.totalSubmittedCount}
            </td>
            <td className={`${styles["amount"]} ${styles["pre-aprroved"]}`}>
              {`${commaSeperatedDecimal(summaryData.totalPreApprovedCount, 0)}`}
              <br />
              {`(${commaSeperatedDecimal(
                (summaryData.totalPreApprovedCount /
                  summaryData.totalSubmittedCount) *
                  100 || 0,
                0
              )}%)`}
            </td>
            <td className={`${styles["amount"]} ${styles["prospects"]}`}>
              {`${commaSeperatedDecimal(summaryData.totalLeadCount, 0)}`}
              <br />
              {`(${commaSeperatedDecimal(
                (summaryData.totalLeadCount /
                  summaryData.totalPreApprovedCount) *
                  100 || 0,
                0
              )}%)`}
            </td>
          </tr>
          {/* <tr className={styles["table-body"]}>
            <td className={styles["title"]}>
              <div>
                <img src={rate1} alt="Rate Icon" />
                Rate #
              </div>
            </td>
            <td className={`${styles["amount"]} ${styles["submitted"]}`}>
              {summaryData.submittedRate * 100 || "-"}%
            </td>
            <td className={`${styles["amount"]} ${styles["pre-aprroved"]}`}>
              {summaryData.preApprovedRate * 100}%
            </td>
            <td className={`${styles["amount"]} ${styles["prospects"]}`}>
              {summaryData.leadRate * 100}%
            </td>
          </tr> */}
          <tr className={styles["table-body"]}>
            <td>
              <div className={styles["title"]}>
                <img src={amount} alt="Rate Icon" />
                <span>Amount</span>
              </div>
            </td>
            <td className={`${styles["amount"]} ${styles["submitted"]}`}>
              {" "}
              {commaSeperatedCurrencyKMB(summaryData.totalSubmittedAmount, 0)}
            </td>
            <td className={`${styles["amount"]} ${styles["pre-aprroved"]}`}>
              {commaSeperatedCurrencyKMB(summaryData.totalPreApprovedAmount, 0)}
            </td>
            <td className={`${styles["amount"]} ${styles["prospects"]}`}>
              {" "}
              {commaSeperatedCurrencyKMB(summaryData.totalLeadAmount, 0)}
            </td>
          </tr>
          <tr className={styles["table-body"]}>
            <td>
              <div className={styles["title"]}>
                <img src={avgAmount} alt="Rate Icon" />
                <span>Avg-Amount</span>
              </div>
            </td>
            <td className={`${styles["amount"]} ${styles["submitted"]}`}>
              {commaSeperatedCurrencyKMB(summaryData.avgSubmittedAmount, 0)}
            </td>
            <td className={`${styles["amount"]} ${styles["pre-aprroved"]}`}>
              {`${commaSeperatedCurrencyKMB(
                summaryData.avgPreApprovedAmount,
                0
              )}`}
              <br />
              {`(${commaSeperatedDecimal(
                (summaryData.avgPreApprovedAmount /
                  summaryData.avgSubmittedAmount) *
                  100 || 0,
                0
              )}%)`}
            </td>
            <td className={`${styles["amount"]} ${styles["prospects"]}`}>
              {` ${commaSeperatedCurrencyKMB(summaryData.avgLeadAmount, 0)}`}
              <br />
              {`(${commaSeperatedDecimal(
                (summaryData.avgLeadAmount / summaryData.avgSubmittedAmount) *
                  100 || 0,
                0
              )}%)`}
            </td>
          </tr>
          {/* <tr className={styles["table-body"]}>
            <td className={styles["title"]}>
              <div>
                <img src={rate2} alt="Rate Icon" />
                Rate $
              </div>
            </td>
            <td className={`${styles["amount"]} ${styles["submitted"]}`}>
              {summaryData.submittedAmtRate * 100 || "-"}%
            </td>
            <td className={`${styles["amount"]} ${styles["pre-aprroved"]}`}>
              {commaSeperatedDecimal(summaryData.preApprovedAmtRate * 100, 0)}%
            </td>
            <td className={`${styles["amount"]} ${styles["prospects"]}`}>
              {" "}
              {commaSeperatedDecimal(summaryData.leadAmtRate * 100, 0)}%
            </td>
          </tr> */}
        </tbody>
      </table>
    </Container>
  );
};

export default SummaryControl;
