import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Logo from "./logo";
import { FormattedMessage } from "react-intl";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import { Col, Row } from "react-bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import _ from "lodash";
import LoanOptions from "./loan-option";
import StickyHeader from "../../components/sticky-header";
import {
  MedicineBottle01Icon,
  Payment02Icon,
  UserIcon,
  Wallet03Icon,
} from "hugeicons-react";
import Footer from "../../components/footer/footer";

const ApplicantResults = ({
  application: consumerApplication = {},
  lenderCollection,
  logoCollection,
  initialInfo = {},
}) => {
  const [loanOptions, setLoanOptions] = useState([]);
  const clickApply = async (item) => {
    window.open(item.lenderLink, "_blank");
  };

  const logos = useMemo(() => {
    const logosDictionary = logoCollection.reduce((accumulator, current) => {
      accumulator[current.organizationId] = current.url;
      return accumulator;
    }, {});
    return logosDictionary;
  }, [logoCollection]);

  useEffect(() => {
    if (
      !consumerApplication?.prequalificationCollection ||
      consumerApplication.prequalificationCollection.length === 0
    ) {
      setLoanOptions([]);
      return;
    }
    const arr = [];
    consumerApplication.prequalificationCollection.forEach((lender) => {
      const options = lender.loanOptionCollection;
      if (Array.isArray(options) && options.length > 0) {
        options.forEach((loanOption) => {
          const logoUrl = lender.logoUrl || logos[lender.lenderId] || "";
          const item = {
            lenderId: lender.lenderId,
            lenderName: lender.lenderName,
            id: loanOption.id,
            logoUrl: logoUrl,
            loanType: options.find((i) => !!i.loanType)?.loanType || "–",
            maxFinancingAmount: lender.approvedAmount,
            approvedAmount: lender.approvedAmount,
            minMonthlyPayment: _.get(loanOption, "monthlyPayment", undefined),
            monthlyPayment: _.get(loanOption, "monthlyPayment", undefined),
            minInterestRate: _.get(loanOption, "interestRate", undefined),
            interestRate: _.get(loanOption, "interestRate", undefined),
            privacyPolicyLink: loanOption?.privacyPolicyUrl || null,
            score: Math.abs(_.get(loanOption, "score", 0)),
            link: loanOption.link,
            redirectLink: loanOption.link || lender.lenderLink || undefined,
            numberOfInstallment: loanOption.numberOfInstallment,
            preConsodilatedType: lender.preConsodilatedType,
          };
          arr.push(item);
        });
      }
    });
    setLoanOptions([...arr]);
  }, [consumerApplication, logos]);

  return (
    <>
      <div className={`${styles["results-container"]}`}>
        <header className={styles["header_patient"]}>
          <div className={`nav container ${styles["header-nav"]}`}>
            <Logo isLeft div className={styles["logo"]} />
            {/* <div className={styles["title"]}>
            <UserIcon className={styles["user-icon"]} />
            <div className={styles["user-title"]}>
              {consumerApplication.applicantInfo?.firstName}
              {"  "}
              {consumerApplication.applicantInfo.lastName}
            </div>
          </div> */}
          </div>
        </header>

        {/* <div className="main main_patient"> */}
        <div className={styles["main_patient_box"]}>
          <div className="bg_white">
            <div className={styles["applicant-container"]}>
              <StickyHeader initalOffset={0} disabled>
                <div className={styles["applicant-header"]}>
                  <Row className={styles["item-header"]}>
                    <Col className={styles["header-title"]} xs={6}>
                      <div className={styles["icon1"]}>
                        <Wallet03Icon />
                      </div>
                      <FormattedMessage id="Amount" />:
                    </Col>
                    <Col className={styles["header-value"]} xs={6}>
                      <span>
                        {consumerApplication.amount
                          ? commaSeperatedCurrency(
                              consumerApplication.amount,
                              0
                            )
                          : "–"}
                      </span>
                    </Col>
                  </Row>
                  <div className={styles["line-break"]} />
                  <Row className={styles["item-header"]}>
                    <Col
                      className={`color_gray ${styles["header-title"]}`}
                      xs={6}
                    >
                      <div className={styles["icon2"]}>
                        <MedicineBottle01Icon />
                      </div>
                      <FormattedMessage id="TreatmentType" />:
                    </Col>
                    <Col className={`fw_bold ${styles["header-value"]}`} xs={6}>
                      {!!consumerApplication.treatmentType && (
                        <span style={{ borderColor: "#CCE4E3" }}>
                          <FormattedMessage
                            id={`TreatmentType_${consumerApplication.treatmentType.toUpperCase()}`}
                          />
                        </span>
                      )}
                    </Col>
                  </Row>
                  <div className={styles["line-break"]} />
                  <Row className={styles["item-header"]}>
                    <Col
                      className={`color_gray ${styles["header-title"]}`}
                      xs={6}
                    >
                      <div className={styles["icon3"]}>
                        <Payment02Icon />
                      </div>
                      <FormattedMessage id="preferredMonthlyPayment" />:
                    </Col>
                    <Col className={`fw_bold ${styles["header-value"]}`} xs={6}>
                      <span style={{ borderColor: "#DCDCF4" }}>
                        {consumerApplication.applicantInfo
                          .preferredMonthlyPayment
                          ? commaSeperatedCurrency(
                              consumerApplication.applicantInfo
                                .preferredMonthlyPayment,
                              0
                            )
                          : "–"}
                      </span>
                    </Col>
                  </Row>
                </div>
              </StickyHeader>
            </div>
          </div>
          <div>
            {consumerApplication.prequalificationCollection && (
              <>
                <div className="">
                  {consumerApplication.prequalificationCollection.length > 1 ? (
                    <div className="t_center fs_lg">
                      <div
                        className={`${styles["congratulations"]} fs_gt_2 fw_bold`}
                      >
                        <FormattedMessage id="Congratulation" />
                      </div>
                      <div className={styles["pre-approved-message"]}>
                        <FormattedMessage id="We got you pre-approved with" />{" "}
                        <span
                          className={`fs_lg color_primary fw_bold ${styles["loanOptions"]}`}
                        >
                          {loanOptions.length}{" "}
                        </span>
                        <span className={styles["lenders-message"]}>
                          <FormattedMessage id="lenders" />
                        </span>
                        .
                      </div>
                    </div>
                  ) : (
                    <div className="t_center fs_lg">
                      <div className="fs_gt_2 fw_bold me_1_sm d_inline_sm">
                        {" "}
                        <FormattedMessage id="Congratulation" />
                      </div>
                      {/* <FormattedMessage id="Andhara bank" /> */}
                      <div className="mt_2 fw_medium mt_0_sm d_inline_sm">
                        <FormattedMessage id="We got you pre-approved with" />{" "}
                        <span className="fs_lg color_primary fw_bold">
                          {
                            consumerApplication.prequalificationCollection[0]
                              .bankName
                          }
                          .
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {/* <PageFooter /> */}
        {/* </div> */}
        <LoanOptions
          prequalicationCollection={loanOptions}
          initialInfo={initialInfo}
          application={consumerApplication}
          lenderCollection={lenderCollection}
        />
      </div>
      <Footer />
    </>
  );
};

ApplicantResults.propTypes = {};

export default ApplicantResults;
