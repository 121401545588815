import commonContextActions from "./common-context-actions";

const commonContextReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
    case commonContextActions.updateSettings:
      return {
        ...state,
        settings: action.payload,
      };
    case commonContextActions.updateConfig:
      return {
        ...state,
        config: action.payload,
      };
    case commonContextActions.updateUser:
      return {
        ...state,
        user: action.payload,
      };
    case commonContextActions.updateUserLoadingStatus:
      return {
        ...state,
        user: {
          ...state.user,
          loadingStatus: action.payload,
        },
      };
    case commonContextActions.updateLogo:
      return {
        ...state,
        logo: action.payload,
      };
    case commonContextActions.updateSqLogo:
      return {
        ...state,
        sqLogo: action.payload,
      };
    case commonContextActions.updateLanguage: {
      let l = action.payload;
      if (
        !l ||
        typeof l !== "string" ||
        ["en", "es"].indexOf(l.toLocaleLowerCase()) < 0
      ) {
        l = "en";
      }
      return {
        ...state,
        language: l.toLocaleLowerCase(),
      };
    }
  }
};

export default commonContextReducer;
