import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Layout from "./layout";

const Help = (props) => {
  return (
    <Layout>
      <h1>Tilte</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
        perferendis, quidem officia, odit sapiente nesciunt qui dolorem vero
        iste excepturi aperiam amet. Eligendi officia harum doloremque quidem
        maiores animi expedita.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur
        natus obcaecati qui quam quasi doloribus quod dolorem necessitatibus
        numquam laborum. Facilis consequatur explicabo non repudiandae at, earum
        maxime rerum tenetur?
      </p>
    </Layout>
  );
};

Help.propTypes = {};

export default Help;
