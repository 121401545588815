const languageType = {
  english: "en",
  spanish: "es",
  getValueByText: (v) => {
    if (!v || typeof v !== "string" || v.length === 0) {
      return null;
    }
    switch (v.toLowerCase()) {
      default:
        return null;
      case "en".toLocaleLowerCase():
        return languageType.english;
      case "es".toLocaleLowerCase():
        return languageType.spanish;
    }
  },

  collection: [
    // { label: "None", value: undefined },
    { label: "English", value: "en" },
    { label: "Español", value: "es" },
  ],
};

export default languageType;
