const commonContextActions = {
  updateUser: "common-context-update-user",
  updateUserLoadingStatus: "common-context-update-user-loading-status",
  updateSettings: "common-context-update-settings",
  updateLogo: "common-context-udate-logo",
  updateSqLogo: "common-context-update-sq-logo",
  updateConfig: "common-update-config-json",
  updateLanguage: "common-update-language",
};

export default commonContextActions;
